import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useProviderContext } from 'context/ProviderContext';

const ProviderOrganizations = () => {
  const { providerOrganizations } = useProviderContext();

  return (
    <Card className="p-3">
      <FalconCardHeader
        title={<h4 className="fw-semi-bold">Current Organizations</h4>}
        light={false}
        titleTag="div"
        className="pb-1"
        menuClassName="align-items-start"
      />
      <Card.Body className="pt-2 pb-3">
        {providerOrganizations.length > 0 ? (
          providerOrganizations.map(({ id, ...rest }, index) => (
            <Organization
              {...rest}
              key={id}
              isLast={index === providerOrganizations.length - 1}
            />
          ))
        ) : (
          <div className="mt-3 text-center d-flex align-items-center justify-content-center">
            <Alert variant="danger">No organization available.</Alert>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const Organization = ({ organization, isLast }) => (
  <Flex
    className={classNames('align-items-center', {
      'border-bottom mb-2 pb-2 border-200': !isLast
    })}
  >
    <Avatar name={organization?.entity_name} src={organization?.logo} />
    <div className="ms-3">
      <h6 className="mb-0 fw-semi-bold">{organization?.entity_name}</h6>
    </div>
  </Flex>
);

Organization.propTypes = {
  organization: PropTypes.object,
  avatar: PropTypes.object,
  isLast: PropTypes.bool
};

export default ProviderOrganizations;
