import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { LOGIN } from 'models/mutation';
import { useMutation } from '@apollo/client';
import { useSignIn } from 'react-auth-kit';
import paths from 'routes/paths';
import jwt_decode from 'jwt-decode';
import LoadingButton from 'components/common/LoadingButton';
import { errorMessage } from 'config';

const LoginForm = ({ hasLabel }) => {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [login, { loading }] = useMutation(LOGIN, {
    onError: () => toast.error(errorMessage, { theme: 'colored' })
  });

  // Handler
  const onSubmit = async e => {
    await login({ variables: { password: e.password, user_name: e.email } })
      .then(res => {
        const decoded = jwt_decode(res.data.Login.access_token);
        if (
          decoded.user.roles.includes('Government Admin') &&
          signIn({
            token: res.data.Login.access_token,
            expiresIn: res.data.Login.expires_in,
            tokenType: res.data.Login.token_type,
            authState: res.data.Login.user,
            refreshToken: res.data.Login.refresh_token, // Only if you are using refreshToken feature
            refreshTokenExpireIn: res.data.Login.expires_in // Only if you are using refreshToken feature
          })
        ) {
          navigate(paths.patientList);
        } else {
          toast.error('Something went wrong!', { theme: 'colored' });
        }
      })
      .catch(error => {
        if (
          String(error).includes(
            'field "user" expected in webhook response, but not found'
          )
        ) {
          toast.error('Wrong email or password! Please try again.', {
            theme: 'colored'
          });
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          name="email"
          type="email"
          isInvalid={!!errors.email}
          {...register('email', {
            required: 'Email is required!'
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors && errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          name="password"
          type="password"
          isInvalid={!!errors.password}
          {...register('password', {
            required: 'Password is required!'
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors && errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input type="checkbox" name="remember" />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={paths.forgotPassword}>
            Forget Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <LoadingButton
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!errors}
          loading={loading}
        >
          Log in
        </LoadingButton>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
