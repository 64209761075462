import paths from './paths';

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/'
    }
  ]
};
export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Patients',
      icon: 'envelope-open',
      active: true,
      children: [
        {
          name: 'Patient List',
          to: paths.patientList,
          active: true
        },
        {
          name: 'Register New Patient',
          to: paths.registerNewPatient,
          active: true
        }
      ]
    },
    {
      name: 'Organizations',
      icon: 'calendar-day',
      active: true,
      children: [
        {
          name: 'Organization List',
          to: paths.organizationList,
          active: true
        }
      ]
    },
    {
      name: 'Healthcare Professionals',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Provider List',
          to: paths.providerList,
          active: true
        }
      ]
    },
    {
      name: 'Staff and Permissions',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'System Users',
          to: paths.systemUsers,
          active: true
        }
      ]
    }
  ]
};

export default [dashboardRoutes, appRoutes];
