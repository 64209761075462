import { gql } from '@apollo/client';

/* --------------------------------------- */
/*                NOTE: AUTH               */
/* --------------------------------------- */
export const LOGIN = gql`
  mutation Login($password: String!, $user_name: String!) {
    Login(password: $password, user_name: $user_name) {
      access_token
      expires_in
      refresh_token
      token_type
      user {
        first_name
        middle_name
        last_name
        email
        email_verified_at
        phone
        address
        user_name
        role_id
      }
    }
  }
`;
export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($email: String = "") {
    ChangePassword(email: $email) {
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String = ""
    $password: String = ""
    $password_confirmation: String = ""
    $token: String = ""
  ) {
    ResetPassword(
      password: $password
      token: $token
      password_confirmation: $password_confirmation
      email: $email
    ) {
      message
    }
  }
`;
export const REFRESH_TOKEN = gql`
  mutation RefreshToken(
    $expires_at: timestamptz = "1296000"
    $access_token_id: String = ""
    $id: String = ""
    $revoked: Boolean = false
  ) {
    insert_oauth_refresh_tokens_one(
      object: {
        access_token_id: $access_token_id
        id: $id
        revoked: $revoked
        expires_at: $expires_at
      }
    ) {
      access_token_id
      id
      created_at
      expires_at
      revoked
      updated_at
    }
  }
`;

// Patient
export const INSERT_PATIENTS = gql`
  mutation INSERT_PATIENTS(
    $address: String = ""
    $age_bracket: String = ""
    $date_of_birth: date = ""
    $district: String = ""
    $country_id: uuid = ""
    $email: String = ""
    $first_name: String = ""
    $gender: String = ""
    $hospital_number: Int = 0
    $language_id: uuid = ""
    $last_name: String = ""
    $marital_status: String = ""
    $middle_name: String = ""
    $nationality_id: uuid = ""
    $new_born: Boolean = false
    $organization_id: uuid = null
    $phone: String = ""
    $post_code: String = ""
    $avatar: String = ""
    $province: String = ""
    $title: String = ""
    $user_name: String = ""
    $password: String = ""
    $id_card_number: Int
    $registered_from_mobile: Boolean = false
    $next_of_kins: [next_of_kin_insert_input!] = {}
  ) {
    insert_patients(
      objects: {
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        user_name: $user_name
        email: $email
        phone: $phone
        gender: $gender
        marital_status: $marital_status
        language_id: $language_id
        nationality_id: $nationality_id
        title: $title
        new_born: $new_born
        date_of_birth: $date_of_birth
        address: $address
        province: $province
        district: $district
        country_id: $country_id
        post_code: $post_code
        age_bracket: $age_bracket
        organization_id: $organization_id
        avatar: $avatar
        password: $password
        hospital_number: $hospital_number
        id_card_number: $id_card_number
        registered_from_mobile: $registered_from_mobile
        next_of_kins: { data: $next_of_kins }
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const INSERT_PATIENT_AVATAR = gql`
  mutation InsertPatientAvatar($base64str: String = "", $patient_id: ID = "") {
    UploadPatientAvatar(base64str: $base64str, patient_id: $patient_id) {
      message
    }
  }
`;
// ? Any User
export const GENERATE_SINGLE_ID_CARD = gql`
  mutation GenerateSingleIdCard {
    generateSingleIDCard {
      card_no
      message
    }
  }
`;

// ? Any User
export const UPDATE_ID_CARD = gql`
  mutation UpdateIdCard(
    $card_no: Int = 0
    $status: String = ""
    $used_date: date = ""
  ) {
    update_id_cards(
      where: { card_no: { _eq: $card_no } }
      _set: { status: $status, used_date: $used_date }
    ) {
      affected_rows
    }
  }
`;
export const INSERT_PATIENT_NOTES = gql`
  mutation InsertPatientNotesOne(
    $government_employee_id: uuid = ""
    $note: String = ""
    $patient_id: uuid = ""
  ) {
    insert_patient_notes_one(
      object: {
        government_employee_id: $government_employee_id
        note: $note
        patient_id: $patient_id
      }
    ) {
      id
    }
  }
`;

// Organization
export const UPDATE_ORGANIZATION_GOVERNMENT_STATUS = gql`
  mutation UpdateOrganizationGovernmentStatus(
    $organization_id: ID = ""
    $status: String = ""
  ) {
    UpdateOrganizationGovernmentStatus(
      organization_id: $organization_id
      status: $status
    ) {
      message
    }
  }
`;
export const UPDATE_ORGANIZATION_LICENSE = gql`
  mutation UpdateOrganizationLicense(
    $license_end_date: String = ""
    $license_start_date: String = ""
    $license_status: String = ""
    $organization_id: ID = ""
  ) {
    UpdateOrganizationLicense(
      license_end_date: $license_end_date
      license_start_date: $license_start_date
      license_status: $license_status
      organization_id: $organization_id
    ) {
      message
    }
  }
`;
export const INSERT_ORGANIZATION_NOTE = gql`
  mutation InsertOrganizationNote(
    $government_employee_id: uuid = ""
    $note: String = ""
    $organization_id: uuid = ""
  ) {
    insert_organization_notes_one(
      object: {
        government_employee_id: $government_employee_id
        note: $note
        organization_id: $organization_id
      }
    ) {
      id
    }
  }
`;

export const UPDATE_DEPARTMENT_STATUS = gql`
  mutation UpdateDepartmentStatus(
    $department_id: ID = ""
    $organization_id: ID = ""
    $status: String = ""
  ) {
    UpdateDepartmentStatus(
      department_id: $department_id
      organization_id: $organization_id
      status: $status
    ) {
      message
    }
  }
`;

// Healthcare Provider
export const UPLOAD_QUALIFICATION_DOCUMENTS = gql`
  mutation UploadQualificationDocuments(
    $base64str: String = ""
    $service_provider_id: ID = ""
  ) {
    UploadQualificationDocuments(
      base64str: $base64str
      service_provider_id: $service_provider_id
    ) {
      service_provider_id
      url
    }
  }
`;
export const INSERT_SERVICE_PROVIDER_NOTES = gql`
  mutation InsertServiceProviderNotesOne(
    $government_employee_id: uuid = ""
    $note: String = ""
    $service_provider_id: uuid = ""
  ) {
    insert_service_provider_notes_one(
      object: {
        government_employee_id: $government_employee_id
        note: $note
        service_provider_id: $service_provider_id
      }
    ) {
      id
    }
  }
`;
