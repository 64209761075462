import React from 'react';
import PropTypes from 'prop-types';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FORGET_PASSWORD } from '../../models/mutation';
import { useMutation } from '@apollo/client';
import paths from '../../routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '../common/LoadingButton';
import { toast } from 'react-toastify';

const ForgetPasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD);

  const onSubmit = async e => {
    await forgetPassword({ variables: { email: e.email } })
      .then(() =>
        navigate({
          pathname: paths.verifyEmail,
          search: createSearchParams({ email: e.email }).toString()
        })
      )
      .catch(error => {
        if (String(error).includes('The selected email is invalid.')) {
          toast.error('Invalid email!', { theme: 'colored' });
        } else {
          console.log(error);
        }
      });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          name="email"
          type="email"
          isInvalid={!!errors.email}
          {...register('email', { required: 'Email is required' })}
        />
        <Form.Control.Feedback tooltip>
          {errors && errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <LoadingButton
          className="w-100"
          type="submit"
          disabled={!errors}
          loading={loading}
        >
          Send reset link
        </LoadingButton>
      </Form.Group>

      <Button
        as={Link}
        color="secondary"
        size="sm"
        className="mt-3"
        to={paths.login}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to login
      </Button>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
