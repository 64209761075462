export default {
  // Auth
  login: '/login',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  verifyEmail: '/verify-email',
  verifyEmailSuccess: '/verify-email-success',
  verifyEmailFailure: '/verify-email-failure',
  notFound: '/404',
  notAuthorized: '/401',
  serverError: '/500',

  // Patient
  patientList: '/patient-list',
  patientDetails: '/patient-details',
  patientDetailsID: '/patient-details/:id',
  registerNewPatient: '/register-new-patient',
  patientDetailsOverview: '/patient-details/:id/overview',
  patientDetailsAppointmentsHistory:
    '/patient-details/:id/appointments-history',
  patientDetailsAdminNotes: '/patient-details/:id/notes',

  // Organization
  organizationList: '/organization-list',
  organizationDetails: '/organization-details',
  organizationDetailsID: '/organization-details/:id',
  organizationDetailsOverview: '/organization-details/:id/overview',
  organizationDetailsLicense: '/organization-details/:id/license',
  organizationDetailsDepartments: '/organization-details/:id/departments',
  organizationDetailsDepartmentsReview:
    '/organization-details/:id/departments-review',
  organizationDetailsNotes: '/organization-details/:id/notes',

  // Healthcare Providers
  providerList: '/provider-list',
  providerDetails: '/provider-details',
  providerDetailsID: '/provider-details/:id',
  providerDetailsOverview: '/provider-details/:id/overview',
  providerDetailsQualifications: '/provider-details/:id/qualifications',
  providerDetailsOrganizations: '/provider-details/:id/organizations',
  providerDetailsNotes: '/provider-details/:id/notes',

  systemUsers: '/system-users',
  registerSystemUser: '/register-system-user'
};
