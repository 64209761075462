import { Spinner } from 'react-bootstrap';
import Flex from './Flex';
import React from 'react';
import logo from 'assets/img/logo.svg';

function LoadingScreen() {
  return (
    <>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh', width: '100vw' }}
      >
        <img src={logo} alt="Logo" width="230" />
        <Spinner animation="border" className="mt-3" variant="primary" />
      </Flex>
    </>
  );
}

export default LoadingScreen;
