import React, { useEffect, useState } from 'react';
import { usePatientContext } from 'context/PatientContext';
import { Card, Image, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import { categorizePerson, getFullName, getRelativePath } from 'helpers/extra';
import logoIcon from 'assets/img/logo_icon.svg';
import paths from 'routes/paths';
import { NavLink } from 'react-router-dom';

const patientNavLinks = [
  {
    id: 1,
    title: 'Overview',
    link: paths.patientDetailsOverview
  },
  {
    id: 2,
    title: 'Appointments History',
    link: paths.patientDetailsAppointmentsHistory
  },
  {
    id: 3,
    title: 'Admin Notes',
    link: paths.patientDetailsAdminNotes
  }
];

const PatientNav = () => {
  const { patientDetails } = usePatientContext();
  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  return (
    <div
      className="font-sans-serif sticky-top"
      style={{ top: stickyStatus ? '5rem' : '0' }}
    >
      <Card className="mb-3">
        <Card.Body>
          <Flex justifyContent="between" alignItems="center" className={'mb-3'}>
            <h6 className="mb-0">Account</h6>
            <SoftBadge pill bg="primary" className="align-self-center fs--2">
              <FontAwesomeIcon icon="user" className="me-1" />
              {categorizePerson(patientDetails?.date_of_birth)}
            </SoftBadge>
          </Flex>
          <div className="text-center">
            <Avatar
              src={patientDetails?.avatar}
              name={getFullName(patientDetails)}
              rounded="circle"
              size="4xl"
              className="border border-5 border-white rounded-circle shadow-sm mb-2"
            />

            <p className="mb-1 fs--1 text-primary fw-medium">
              {patientDetails?.title} {getFullName(patientDetails)}
            </p>
            <p className="mb-0 fs--2 text-700 font-base">
              ID {patientDetails?.hospital_number}
            </p>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div className="mb-3">
            <Image src={logoIcon} height="24px" width="24px" />
            <span className="ms-2 fs--1 fw-medium text-900">
              Patient Profile
            </span>
          </div>
          <Nav variant="pills" className="flex-column">
            {patientNavLinks.map(({ id, link, title }) => (
              <Nav.Item key={id}>
                <Nav.Link
                  as={NavLink}
                  className="fs--1 fw-medium patient-profile-nav"
                  to={getRelativePath(link, paths.patientDetailsID)}
                >
                  {title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PatientNav;
