import React from 'react';
import { useForm } from 'react-hook-form';
import { Card, Form } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';
import { useAuthHeader } from 'react-auth-kit';
import jwt_decode from 'jwt-decode';
import { useMutation } from '@apollo/client';
import { INSERT_SERVICE_PROVIDER_NOTES } from 'models/mutation';
import { GET_PROVIDER_NOTES } from 'models/query';
import { toast } from 'react-toastify';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { formatDate } from 'helpers/extra';
import { useProviderContext } from 'context/ProviderContext';

const ProviderNotes = () => {
  const authHeader = useAuthHeader();
  const decoded = jwt_decode(authHeader());
  const { providerDetails, providerNotes } = useProviderContext();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [insertNote, { loading }] = useMutation(INSERT_SERVICE_PROVIDER_NOTES, {
    refetchQueries: [
      {
        query: GET_PROVIDER_NOTES,
        variables: { service_provider_id: providerDetails.id }
      }
    ],
    onCompleted: () => toast.success('Note uploaded!', { theme: 'colored' }),
    onError: () =>
      toast.error('Something went wrong! try again', { theme: 'colored' })
  });

  const onSubmit = async event => {
    await insertNote({
      variables: {
        government_employee_id: decoded?.user?.id,
        note: event.note,
        service_provider_id: providerDetails.id
      }
    });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="p-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                name="note"
                as="textarea"
                rows={3}
                isInvalid={!!errors.note}
                {...register('note', {
                  required: 'Note is required!'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.note?.message}
              </Form.Control.Feedback>
              <small className="text-400">
                * Separate your Instructions with comma
              </small>
            </Form.Group>
            <Form.Group>
              <LoadingButton
                type="submit"
                color="primary"
                disabled={!errors}
                loading={loading}
              >
                Add
              </LoadingButton>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      {providerNotes.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns()}
          data={providerNotes}
          selection
          sortable
          pagination
          perPage={14}
          rowCount={providerNotes.length}
        >
          <Card>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'lg',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={providerNotes.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card>
          <Card.Body className="text-center">No notes available.</Card.Body>
        </Card>
      )}
    </>
  );
};

const columns = () => [
  {
    accessor: 'created_at',
    Header: 'ID',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return formatDate(created_at);
    }
  },
  {
    accessor: 'note',
    Header: 'Note',
    cellProps: {
      className: 'w-100'
    }
  }
];

export default ProviderNotes;
