import React, { useEffect, useState } from 'react';
import { GET_ORGANIZATIONS } from 'models/query';
import { useMutation, useQuery } from '@apollo/client';
import { formatDate, shortId } from 'helpers/extra';
import { useCommonDataContext } from 'context/CommonDataContext';
import { Card, Dropdown } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import _ from 'lodash';
import LoadingContainer from '../common/LoadingContainer';
import ErrorContainer from '../common/ErrorContainer';
import OrganizationListHeader from './OrganizationListHeader';
import CardDropdown from '../common/CardDropdown';
import { toast } from 'react-toastify';
import { UPDATE_ORGANIZATION_GOVERNMENT_STATUS } from 'models/mutation';
import { errorMessage } from 'config';

const OrganizationList = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [organizationList, setOrganizationList] = useState([]);
  const { nationalities, languages } = useCommonDataContext();

  const organizations = useQuery(GET_ORGANIZATIONS, {
    onError: () => toast.error(errorMessage, { theme: 'colored' })
  });

  useEffect(() => {
    if (organizations.data) {
      let array = [];
      organizations.data.organizations.map(item => {
        array.push({
          id: item?.id,
          entity_name: item?.entity_name,
          avatar: item?.logo,
          phone: item?.phone,
          email: item?.email,
          address: item?.address,
          status: item?.government_status,
          type: item?.type?.name,
          created_at: item?.created_at
        });
      });
      setOrganizationList(array);
    }
  }, [organizations.data, nationalities, languages]);

  if (organizations.loading) return <LoadingContainer />;
  if (organizations.error) return <ErrorContainer />;

  return (
    <>
      {organizationList.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns()}
          data={
            organizationList.length > 0
              ? _.filter(organizationList, function (v) {
                  return String(v)
                    .toLowerCase()
                    .includes(globalFilter.toLowerCase());
                })
              : []
          }
          selection
          sortable
          pagination
          perPage={14}
          rowCount={organizationList.length}
        >
          <Card>
            <Card.Header>
              <OrganizationListHeader
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'lg',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card>
          <FalconCardHeader title="Organization List" titleTag="h5" />
          <Card.Body className="text-center">
            No organizations available.
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const columns = () => [
  {
    accessor: 'id',
    Header: 'ID',
    Cell: rowData => {
      const { id } = rowData.row.original;
      return shortId(id);
    }
  },
  {
    accessor: 'entity_name',
    Header: 'Patient Name',
    Cell: rowData => {
      const { id, avatar, entity_name } = rowData.row.original;

      return (
        <Link to={paths.organizationDetails + `/${id}`}>
          <Flex alignItems="center">
            <Avatar
              src={avatar}
              name={entity_name}
              size="xl"
              className="me-2"
            />
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{entity_name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'address',
    Header: 'Address'
  },
  {
    accessor: 'created_at',
    Header: 'Added In',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return formatDate(created_at);
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    cellProps: {
      className: 'text-capitalize'
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      const [changeStatus, { loading }] = useMutation(
        UPDATE_ORGANIZATION_GOVERNMENT_STATUS,
        {
          refetchQueries: [{ query: GET_ORGANIZATIONS }],
          onCompleted: () => {
            toast.success('Status Updated', {
              theme: 'colored'
            });
          },
          onError: () => {
            toast.error('Something went wrong! Try again.', {
              theme: 'colored'
            });
          }
        }
      );

      return (
        <CardDropdown iconClassName="fs--1" drop="start">
          <div className="py-2">
            {['active', 'inactive', 'pending'].map((item, index) => (
              <Dropdown.Item
                key={index}
                disabled={loading}
                onClick={async () => {
                  await changeStatus({
                    variables: {
                      organization_id: id,
                      status: item
                    }
                  });
                }}
                className="text-danger text-capitalize"
              >
                {item}
              </Dropdown.Item>
            ))}
          </div>
        </CardDropdown>
      );
    }
  }
];

export default OrganizationList;
