import { createRefresh } from 'react-auth-kit';
import { useMutation } from '@apollo/client';
import { REFRESH_TOKEN } from 'models/mutation';

const refreshApi = createRefresh({
  interval: 120, // Refresh the token in every 10 minutes
  refreshApiCallback: async () => {
    const [refreshTokenMutation] = useMutation(REFRESH_TOKEN, {
      variables: {
        id: Math.floor(Math.random() * 1000000000),
        access_token_id: 'test'
      }
    });
    try {
      let response = await refreshTokenMutation().catch(error =>
        console.log(error)
      );
      return {
        isSuccess: true,
        newAuthToken:
          response.data.insert_oauth_refresh_tokens_one.access_token_id,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 60
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false
      };
    }
  }
});

export default refreshApi;
