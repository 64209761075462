import React, { useEffect, useState } from 'react';
import { GET_PATIENTS } from 'models/query';
import { useQuery } from '@apollo/client';
import { calculateAge, getFullName, getNameById } from 'helpers/extra';
import { useCommonDataContext } from 'context/CommonDataContext';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PatientListHeader from './PatientListHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import _ from 'lodash';
import LoadingContainer from '../common/LoadingContainer';
import ErrorContainer from '../common/ErrorContainer';
import { toast } from 'react-toastify';

const PatientList = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [patientList, setPatientList] = useState([]);
  const { nationalities, languages } = useCommonDataContext();

  const patients = useQuery(GET_PATIENTS, {
    onError: () => toast.error('Something went wrong!', { theme: 'colored' })
  });

  useEffect(() => {
    if (patients.data) {
      let array = [];
      patients.data.patients.map(item => {
        array.push({
          id: item?.id,
          hospital_number: item?.hospital_number,
          avatar: item?.avatar,
          first_name: item?.first_name,
          last_name: item?.last_name,
          name: getFullName(item),
          phone: item?.phone,
          email: item?.email,
          age: calculateAge(item?.date_of_birth),
          gender: item?.gender,
          marital_status: item?.marital_status,
          date_of_birth: item?.date_of_birth,
          nationality: getNameById(item.nationality_id, nationalities),
          language: getNameById(item.language_id, languages),
          registration: item?.created_at
        });
      });
      setPatientList(array);
    }
  }, [patients.data, nationalities, languages]);

  if (patients.loading) return <LoadingContainer />;
  if (patients.error) return <ErrorContainer />;

  return (
    <>
      {patientList.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns()}
          data={
            patientList.length > 0
              ? _.filter(patientList, function (v) {
                  return String(v)
                    .toLowerCase()
                    .includes(globalFilter.toLowerCase());
                })
              : []
          }
          selection
          sortable
          pagination
          perPage={14}
          rowCount={patientList.length}
        >
          <Card>
            <Card.Header>
              <PatientListHeader
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card>
          <FalconCardHeader title="Patients List" titleTag="h5" />
          <Card.Body className="text-center">No patients available.</Card.Body>
        </Card>
      )}
    </>
  );
};

const columns = () => [
  {
    accessor: 'hospital_number',
    Header: 'H.No'
  },
  {
    accessor: 'name',
    Header: 'Patient Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, avatar } = rowData.row.original;

      return (
        <Link to={paths.patientDetails + `/${id}`}>
          <Flex alignItems="center">
            <Avatar
              src={avatar}
              name={getFullName(rowData.row.original)}
              size="xl"
              className="me-2"
            />
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {getFullName(rowData.row.original)}
              </h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone'
  },
  {
    accessor: 'gender',
    Header: 'Gender'
  },
  {
    accessor: 'age',
    Header: 'Age',
    cellProps: {
      className: 'fw-bold'
    }
  },
  {
    accessor: 'registration',
    Header: 'Registration'
  },
  {
    accessor: 'nationality',
    Header: 'Location',
    cellProps: {
      className: 'fw-bold'
    }
  }
];

export default PatientList;
