import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { useAuthHeader } from 'react-auth-kit';
import { setContext } from '@apollo/client/link/context';
import LoadingScreen from 'components/common/LoadingScreen';

const App = () => {
  const authHeader = useAuthHeader();
  const cache = new InMemoryCache();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL
  });

  const authLink = setContext((_, { headers }) => {
    if (authHeader()) {
      return {
        headers: {
          ...headers,
          authorization: String(authHeader()),
          'x-hasura-admin-secret': process.env.REACT_APP_ADMIN_SECRET
        }
      };
    } else {
      return {
        headers: {
          ...headers
        }
      };
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: true,
    cache
  });

  if (!client) {
    return <LoadingScreen />;
  }

  return (
    <ApolloProvider client={client}>
      <Router er basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </ApolloProvider>
  );
};

export default App;
