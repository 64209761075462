import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePatientContext } from 'context/PatientContext';
import { getFullName, getNameById } from 'helpers/extra';
import { useCommonDataContext } from 'context/CommonDataContext';

const EmergencyContacts = () => {
  const { relations } = useCommonDataContext();
  const { emergencyContacts } = usePatientContext();

  return (
    <Card className="p-2">
      <FalconCardHeader
        title={<h4 className="fw-semi-bold">Emergency Contacts</h4>}
        light={false}
        titleTag="div"
        className="pb-1"
        menuClassName="align-items-start"
      />
      <Card.Body className="pt-2 pb-3">
        {emergencyContacts.map(({ id, ...rest }, index) => (
          <ActiveUser
            {...rest}
            key={id}
            relations={relations}
            isLast={index === emergencyContacts.length - 1}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

const ActiveUser = ({
  first_name,
  middle_name,
  last_name,
  avatar,
  relation_id,
  relations,
  isLast
}) => (
  <Flex
    className={classNames('align-items-center', {
      'border-bottom mb-2 pb-2 border-200': !isLast
    })}
  >
    <Avatar
      name={getFullName({ first_name, middle_name, last_name })}
      src={avatar}
    />
    <div className="ms-3">
      <h6 className="mb-0 fw-semi-bold">
        {getFullName({ first_name, middle_name, last_name })}
      </h6>
      <p className="text-500 fs--2 mb-0">
        {getNameById(relation_id, relations)}
      </p>
    </div>
  </Flex>
);

ActiveUser.propTypes = {
  first_name: PropTypes.string,
  middle_name: PropTypes.string,
  last_name: PropTypes.string,
  avatar: PropTypes.object,
  relation_id: PropTypes.string,
  relations: PropTypes.array,
  isLast: PropTypes.bool
};

export default EmergencyContacts;
