import { gql } from '@apollo/client';

// Common

export const GET_STATIC_DATA = gql`
  query GetStaticData {
    type(where: { _not: { name: { _eq: "Default" } } }) {
      id
      name
    }
    roles {
      id
      name
    }
    departments {
      id
      name
      description
    }
    countries {
      id
      name
    }
    specialities {
      id
      name
    }
    languages {
      id
      name
    }
    nationalities {
      id
      name
    }
    payment_method {
      id
      name
    }
    relations {
      id
      name
    }
    kanban_status {
      id
      status
    }
    statuses {
      id
      title
    }
  }
`;

// Patients
export const GET_PATIENTS = gql`
  query GetPatients {
    patients {
      id
      first_name
      email
      phone
      address
      middle_name
      last_name
      title
      date_of_birth
      marital_status
      language_id
      nationality_id
      new_born
      district
      post_code
      gender
      avatar
      organization_id
      is_pregnant
      age_bracket
      spouse_id
      user_id
      hospital_number
      id_card_number
      created_at
      updated_at
    }
  }
`;
export const GET_PATIENT_BY_PK = gql`
  query PatientByPK($id: uuid = "") {
    patients_by_pk(id: $id) {
      address
      age_bracket
      avatar
      city
      country_id
      created_at
      date_of_birth
      district
      email
      first_name
      gender
      id
      is_pregnant
      last_name
      marital_status
      middle_name
      new_born
      organization_id
      password
      phone
      post_code
      province
      spouse_id
      title
      updated_at
      user_id
      user_name
      hospital_number
      id_card_number
      language {
        id
        name
        country_code
      }
      nationality {
        id
        name
      }
      Immunisations {
        Immunisation
        created_at
        date_given
        id
      }
    }
  }
`;
export const GET_NEXT_OF_KIN = gql`
  query GetNextOfKin($patient_id: uuid = "") {
    next_of_kin(where: { patient_id: { _eq: $patient_id } }) {
      id
      avatar
      first_name
      last_name
      middle_name
      phone_number
      relation_id
      other_relation
    }
  }
`;
export const GET_TICKETS_BY_PATIENT = gql`
  query GetTicketsByPatient($patient_id: uuid = "") {
    tickets(where: { patient_id: { _eq: $patient_id } }) {
      id
      date
      time
      created_at
      updated_at
      kanban_status_id
      organization {
        entity_name
      }
      service_provider {
        first_name
        middle_name
        last_name
      }
    }
  }
`;
export const SEARCH_ID_CARD = gql`
  query SearchIdCard($card_no: Int = 0) {
    id_cards(where: { card_no: { _eq: $card_no } }) {
      id
      card_no
      activated_by
      generated_by
      genaration_date
      created_at
      printed_status
      status
      updated_at
      used_date
    }
  }
`;
export const GET_PATIENT_NOTES = gql`
  query GetPatientNotes($patient_id: uuid = "") {
    patient_notes(where: { patient_id: { _eq: $patient_id } }) {
      id
      government_employee_id
      note
      created_at
    }
  }
`;

// Organization
export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      id
      entity_name
      phone
      email
      created_at
      logo
      government_status
      address
      type {
        name
      }
    }
  }
`;
export const GET_ORGANIZATIONS_BY_PK = gql`
  query GetOrganizationsByPK($organization_id: uuid = "") {
    organizations_by_pk(id: $organization_id) {
      address
      created_at
      email
      entity_name
      government_status
      id
      license_end_date
      license_start_date
      license_status
      logo
      number
      phone
      service_type
      type {
        name
      }
    }
  }
`;
export const GET_ORGANIZATION_NOTES = gql`
  query GetOrganizationNotes($organization_id: uuid = "") {
    organization_notes(where: { organization_id: { _eq: $organization_id } }) {
      id
      government_employee_id
      note
      created_at
    }
  }
`;
export const GET_ORGANIZATION_DEPARTMENT = gql`
  query GetOrganizationDepartment($organization_id: uuid = "") {
    department_organization(
      where: { organization_id: { _eq: $organization_id } }
    ) {
      id
      department_id
      government_status
    }
  }
`;
export const GET_DEPARTMENT_DOCUMENTS = gql`
  query departmentDocuments($organization_id: uuid = "") {
    department_documents(
      where: { organization_id: { _eq: $organization_id } }
    ) {
      department_id
      organization_id
      name
      url
      id
    }
  }
`;

// Healthcare Providers
export const GET_HEALTHCARE_PROVIDERS = gql`
  query GetHealthcareProviders {
    service_providers {
      id
      first_name
      middle_name
      last_name
      phone
      email
      created_at
      avatar
      role {
        name
      }
    }
  }
`;
export const GET_HEALTHCARE_PROVIDER_BY_PK = gql`
  query GetHealthcareProviderByPK($service_provider_id: uuid = "") {
    service_providers_by_pk(id: $service_provider_id) {
      id
      first_name
      last_name
      middle_name
      avatar
      address
      phone
      email
      created_at
      role {
        name
      }
    }
  }
`;
export const GET_SERVICE_PROVIDER_QUALIFICATION_DOCUMENTS_ID = gql`
  query GetServiceProviderQualificationDocumentsId($service_provider_id: uuid) {
    qualification_document_service_provider(
      where: { service_provider_id: { _eq: $service_provider_id } }
    ) {
      qualification_document {
        id
        name
        url
      }
    }
  }
`;
export const GET_HEALTHCARE_PROVIDER_ORGANIZATIONS = gql`
  query GetHealthcareProviderOrganizations($service_provider_id: uuid = "") {
    organization_service_provider(
      where: { service_provider_id: { _eq: $service_provider_id } }
    ) {
      id
      approved
      created_at
      organization {
        entity_name
        logo
      }
    }
  }
`;

// System Users
export const GET_SYSTEM_USERS = gql`
  query GetSystemUsers {
    government_employees {
      id
      first_name
      last_name
      created_at
      avatar
      email
      phone
    }
  }
`;
export const GET_PROVIDER_NOTES = gql`
  query GetProviderNotes($service_provider_id: uuid = "") {
    service_provider_notes(
      where: { service_provider_id: { _eq: $service_provider_id } }
    ) {
      id
      government_employee_id
      note
      created_at
    }
  }
`;
