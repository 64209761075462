import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import paths from 'routes/paths';
import { useNavigate } from 'react-router-dom';

const SystemUserListHeader = ({ globalFilter, setGlobalFilter }) => {
  const navigate = useNavigate();
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="text-nowrap fs-1 me-3">Users List</h5>
        <AdvanceTableSearchBox
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div id="orders-actions" style={{ display: 'flex' }}>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="user"
            transform="shrink-3"
            className="me-2"
            onClick={() => navigate(paths.registerSystemUser)}
          >
            <span className="d-none d-sm-inline-block ms-1">Add New User</span>
          </IconButton>
        </div>
      </Col>
    </Row>
  );
};

SystemUserListHeader.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default SystemUserListHeader;
