import React, { useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import _ from 'lodash';
import { Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useCommonDataContext } from 'context/CommonDataContext';
import { useQuery } from '@apollo/client';
import { GET_TICKETS_BY_PATIENT } from 'models/query';
import { formatDate, getFullName, getStatusById, shortId } from 'helpers/extra';
import SoftBadge from 'components/common/SoftBadge';
import { usePatientContext } from 'context/PatientContext';
import AdvanceTableFooter from '../../common/advance-table/AdvanceTableFooter';
import AppointmentHistoryHeader from './AppointmentHistoryHeader';

const AppointmentHistory = () => {
  const { patientDetails } = usePatientContext();
  const { kanbanStatus } = useCommonDataContext();
  const [globalFilter, setGlobalFilter] = useState('');
  const [ticketsList, setTicketsList] = useState([]);

  const tickets = useQuery(GET_TICKETS_BY_PATIENT, {
    variables: {
      patient_id: patientDetails.id
    }
  });

  useEffect(() => {
    if (tickets.data) {
      let array = [];
      tickets.data.tickets.map(item => {
        array.push({
          id: item?.id,
          date: item?.date,
          time: item?.time,
          created_at: item?.created_at,
          updated_at: item?.updated_at,
          status: getStatusById(item?.kanban_status_id, kanbanStatus),
          organization_name: item?.organization?.entity_name,
          professional: getFullName(item?.service_provider)
        });
      });
      setTicketsList(array);
    }
  }, [tickets.data, kanbanStatus]);

  return (
    <>
      {ticketsList.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns()}
          data={
            ticketsList.length > 0
              ? _.filter(ticketsList, function (v) {
                  return String(v)
                    .toLowerCase()
                    .includes(globalFilter.toLowerCase());
                })
              : []
          }
          selection
          sortable
          pagination
          perPage={14}
          rowCount={ticketsList.length}
        >
          <Card>
            <Card.Header>
              <AppointmentHistoryHeader
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'lg',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={ticketsList.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
                rowsPerPageOptions={[14, 10, 5]}
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card>
          <FalconCardHeader title="Patients List" titleTag="h5" />
          <Card.Body className="bg-light">
            <p>No patients available.</p>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const columns = () => [
  {
    accessor: 'id',
    Header: 'Ref',
    Cell: rowData => {
      const { id } = rowData.row.original;
      return shortId(id);
    }
  },
  {
    accessor: 'date',
    Header: 'Date',
    Cell: rowData => {
      const { date, time } = rowData.row.original;

      return <>{formatDate(date) + ' ' + time}</>;
    }
  },
  {
    accessor: 'professional',
    Header: 'Professional'
  },
  {
    accessor: 'organization_name',
    Header: 'Organization'
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge bg="primary" className="me-2">
          {status}
        </SoftBadge>
      );
    }
  }
];

export default AppointmentHistory;
