import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';

const ProviderListHeader = ({ globalFilter, setGlobalFilter }) => {
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="text-nowrap fs-1 me-3">Healthcare Providers List</h5>
        <AdvanceTableSearchBox
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Col>
    </Row>
  );
};

ProviderListHeader.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default ProviderListHeader;
