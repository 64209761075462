import React from 'react';
import { Alert } from 'react-bootstrap';

function ErrorContainer() {
  return (
    <div className="p-3 text-center d-flex h-100 w-100 align-items-center justify-content-center">
      <Alert variant="danger">Something went wrong. Please try again.</Alert>
    </div>
  );
}

export default ErrorContainer;
