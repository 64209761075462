import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { INSERT_PATIENTS } from '../../models/mutation';
import { GET_PATIENTS } from '../../models/query';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Avatar from '../common/Avatar';
import { isIterableArray } from '../../helpers/utils';
import avatarImg from '../../assets/img/team/avatar.png';
import FalconDropzone from '../common/FalconDropzone';
import Flex from '../common/Flex';
import cloudUpload from '../../assets/img/icons/cloud-upload.svg';
import PhoneInput from 'react-phone-input-2';
import IconButton from '../common/IconButton';
import Divider from '../common/Divider';
import LoadingButton from '../common/LoadingButton';

const RegisterSystemUser = () => {
  const [avatar, setAvatar] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const [{ loading }] = useMutation(INSERT_PATIENTS, {
    refetchQueries: [{ query: GET_PATIENTS }]
  });

  const onSubmit = async event => {
    console.log(event);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="userRegistrationForm">
      <Card className="mb-3">
        <FalconCardHeader
          title={<h5>User Information</h5>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />
        <Card.Body className="pb-3">
          <Row className="mb-3">
            <Col lg={12}>
              <div className="border-1 border-300 border rounded">
                <Row className="p-3">
                  <Col md="auto">
                    <Avatar
                      size="4xl"
                      src={
                        avatar.length > 0
                          ? isIterableArray(avatar)
                            ? avatar[0]?.base64 || avatar[0]?.src
                            : ''
                          : avatarImg
                      }
                    />
                  </Col>
                  <Col md>
                    <FalconDropzone
                      files={avatar}
                      onChange={files => {
                        setAvatar(files);
                      }}
                      multiple={false}
                      accept="image/*"
                      placeholder={
                        <>
                          <Flex justifyContent="center" className="mt-2">
                            <img
                              src={cloudUpload}
                              alt=""
                              width={25}
                              className="me-2"
                            />
                            <p className="mb-0 fs-0 text-700">
                              Upload your profile picture
                            </p>
                          </Flex>
                          <p className="mx-auto mb-0 w-75 text-400">
                            Upload a 300x300 jpg image with a maximum size of
                            400KB
                          </p>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="g-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                First Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.first_name}
                {...register('first_name', {
                  required: 'First name is required!'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.first_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.last_name}
                {...register('last_name', {
                  required: 'Last name is required!'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.last_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="g-3 mt-0">
            <Form.Group as={Col} md={6}>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                isInvalid={!!errors.email}
                {...register('email', {
                  pattern: {
                    value:
                      /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                    message: 'Email must be valid'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                Phone <span className="text-danger">*</span>
              </Form.Label>
              <Controller
                name="phone"
                control={control}
                errors={errors}
                rules={{
                  required: 'Phone is required',
                  min: { value: 1000000000, message: 'Phone must be valid' },
                  max: { value: 999999999999, message: 'Phone must be valid' }
                }}
                render={({ field }) => (
                  <>
                    <PhoneInput
                      alwaysDefaultMask="true"
                      country={'so'}
                      isInvalid={!!errors.phone}
                      enableSearch="true"
                      className={
                        errors.phone && errors.phone.message ? 'is-invalid' : ''
                      }
                      onChange={phone => field.onChange(phone)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone && errors.phone.message}
                    </Form.Control.Feedback>
                  </>
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  {...register('password', {
                    required: 'Password is required!'
                  })}
                />
                <IconButton
                  variant="outline-secondary"
                  onClick={() => {}}
                  icon="lock"
                >
                  Generate Password
                </IconButton>
              </InputGroup>
            </Form.Group>
          </Row>
          <Divider />
          <Flex justifyContent="between" alignItems="center">
            <h5 className="fw-semi-bold">Super Admin</h5>
            <Flex alignItems="center">
              <Form.Check
                id="adminCheckbox"
                type="checkbox"
                label="Admin"
                value="admin"
                {...register('admin')}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="fw-semi-bold">Patient</h5>
            <Flex alignItems="center" className="gap-3">
              <Form.Check
                id="patientViewCheckbox"
                type="checkbox"
                label="View"
                value="view"
                {...register('patients')}
              />
              <Form.Check
                id="patientAddEditCheckbox"
                type="checkbox"
                label="Add/Edit"
                value="add-edit"
                {...register('patients')}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="fw-semi-bold">Organizations</h5>
            <Flex alignItems="center" className="gap-3">
              <Form.Check
                id="organizationViewCheckbox"
                type="checkbox"
                label="View"
                value="view"
                {...register('organizations')}
              />
              <Form.Check
                id="organizationAddEditCheckbox"
                type="checkbox"
                label="Add/Edit"
                value="add-edit"
                {...register('organizations')}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="fw-semi-bold">Healthcare Professionals</h5>
            <Flex alignItems="center" className="gap-3">
              <Form.Check
                id="hpViewCheckbox"
                type="checkbox"
                label="View"
                value="view"
                {...register('healthcare_professionals')}
              />
              <Form.Check
                id="hpAddEditCheckbox"
                type="checkbox"
                label="Add/Edit"
                value="add-edit"
                {...register('healthcare_professionals')}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="between" alignItems="center">
            <h5 className="fw-semi-bold">Staff and Permissions</h5>
            <Flex alignItems="center" className="gap-3">
              <Form.Check
                id="spViewCheckbox"
                type="checkbox"
                label="View"
                value="view"
                {...register('staff_permissions')}
              />
              <Form.Check
                id="spAddEditCheckbox"
                type="checkbox"
                label="Add/Edit"
                value="add-edit"
                {...register('staff_permissions')}
              />
            </Flex>
          </Flex>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="p-4">
          <Row className="g-2">
            <Col xs={12} className="text-end">
              <LoadingButton
                type="submit"
                variant="primary"
                className="w-100 w-sm-auto"
                size="lg"
                disabled={!errors}
                loading={loading}
              >
                Create Now
              </LoadingButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default RegisterSystemUser;
