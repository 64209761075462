import React from 'react';
import { Col, Form, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InputPlaceholder = ({ label, props }) => {
  return (
    <Col {...props}>
      <Placeholder animation="glow">
        <Form.Label>{label}</Form.Label>
        <Placeholder
          xs={12}
          size="lg"
          className="rounded"
          style={{ height: '36px' }}
        />
      </Placeholder>
    </Col>
  );
};

InputPlaceholder.propTypes = {
  label: PropTypes.string,
  props: PropTypes.any
};

export default InputPlaceholder;
