import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useOrganizationContext } from 'context/OrganizationContext';
import SoftBadge from 'components/common/SoftBadge';
import OrganizationDepartmentReview from './OrganizationDepartmentReview';
import { useCommonDataContext } from 'context/CommonDataContext';
import { getNameById } from 'helpers/extra';
import _ from 'lodash';

const OrganizationDepartments = () => {
  const { departments } = useOrganizationContext();
  const { departments: allDepartments } = useCommonDataContext();
  const [globalFilter, setGlobalFilter] = useState('');
  const [selected, setSelected] = useState('');

  return (
    <>
      {!selected ? (
        <>
          <Card className="mb-3">
            <Card.Body className="p-3">
              <Row>
                <Col md="auto">
                  <h5 className="fw-semi-bold">Departments</h5>
                </Col>
                <Col md="auto">
                  <InputGroup className="position-relative">
                    <FormControl
                      value={globalFilter || ''}
                      onChange={({ target: { value } }) => {
                        setGlobalFilter(value);
                      }}
                      size="sm"
                      id="search"
                      type="search"
                      className="shadow-none"
                    />
                    <InputGroup.Text className="bg-transparent">
                      <FontAwesomeIcon
                        icon="search"
                        className="fs--1 text-600"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {departments?.length > 0 ? (
            _.filter(departments, function (v) {
              return String(getNameById(v?.department_id, allDepartments))
                .toLowerCase()
                .includes(globalFilter.toLowerCase());
            })?.map((item, index) => (
              <Card key={index} className="mb-3">
                <Row className="align-items-center g-0">
                  <Col md="auto">
                    <FalconCardHeader
                      title={
                        <h5 className="fw-semi-bold">
                          {getNameById(item.department_id, allDepartments)}
                        </h5>
                      }
                      light={false}
                      titleTag="div"
                      className="pb-2"
                      menuClassName="align-items-start"
                    />
                  </Col>
                  <Col md="auto">
                    <SoftBadge bg="primary" className="text-capitalize">
                      {item?.government_status}
                    </SoftBadge>
                  </Col>
                </Row>
                {allDepartments.find(x => x.id === item.department_id)
                  ?.description && (
                  <Card.Body>
                    {
                      allDepartments.find(x => x.id === item.department_id)
                        ?.description
                    }
                  </Card.Body>
                )}
                <Card.Footer className="pt-0 d-flex align-items-center justify-content-between">
                  <h5 className="fs-1">Department Application</h5>
                  <Button
                    className="btn-warning"
                    onClick={() => {
                      setSelected(item?.department_id);
                    }}
                  >
                    Review
                  </Button>
                </Card.Footer>
              </Card>
            ))
          ) : (
            <Card>
              <Card.Body className="text-center">
                No departments available.
              </Card.Body>
            </Card>
          )}
        </>
      ) : (
        <OrganizationDepartmentReview
          department_id={selected}
          setSelected={setSelected}
        />
      )}
    </>
  );
};

export default OrganizationDepartments;
