import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useAuthHeader, useAuthUser, useSignOut } from 'react-auth-kit';
import { getFullName } from 'helpers/extra';
import jwt_decode from 'jwt-decode';

const ProfileDropdown = () => {
  const signOut = useSignOut();
  const auth = useAuthUser();
  const authHeader = useAuthHeader();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={auth()?.avatar} name={getFullName(auth())} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as="div" className="text-900" disabled>
            {getFullName(auth())}
            <br />
            <small className="text-capitalize text-500">
              {jwt_decode(authHeader()).user.roles[0]}
            </small>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as="button"
            onClick={async () => {
              signOut();
            }}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
