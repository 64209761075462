import fabmedicPackage from '../package.json';

export const version = fabmedicPackage.version;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
};
export const errorMessage = 'Something went wrong! Try again';

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
