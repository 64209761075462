import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { GET_STATIC_DATA } from 'models/query';
import { useQuery } from '@apollo/client';
import { moveOtherToBottom } from 'helpers/extra';

export const CommonDataContext = createContext({
  types: [],
  roles: [],
  departments: [],
  countries: [],
  specialities: [],
  languages: [],
  nationalities: [],
  paymentMethods: [],
  relations: [],
  kanbanStatus: [],
  statuses: []
});

export const CommonDataContextProvider = ({ children }) => {
  const [types, setTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [countries, setCountries] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [relations, setRelations] = useState([]);
  const [kanbanStatus, setKanbanStatus] = useState([]);
  const [statuses, setStatues] = useState([]);

  useQuery(GET_STATIC_DATA, {
    onCompleted: data => {
      setTypes(data.type);
      setRoles(data.roles);
      setDepartments(data.departments);
      setCountries(data.countries);
      setSpecialities(moveOtherToBottom(data.specialities));
      setLanguages(moveOtherToBottom(data.languages));
      setNationalities(data.nationalities);
      setPaymentMethods(data.payment_method);
      setRelations(moveOtherToBottom(data.relations));
      setKanbanStatus(data.kanban_status);
      setStatues(data.statuses);
    }
  });

  return (
    <CommonDataContext.Provider
      value={{
        types,
        roles,
        departments,
        countries,
        specialities,
        languages,
        nationalities,
        paymentMethods,
        relations,
        kanbanStatus,
        statuses
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};

CommonDataContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useCommonDataContext = () => {
  const context = useContext(CommonDataContext);
  if (context === undefined) {
    throw new Error(
      'useCommonDataContext must be used within a CommonDataContextProvider'
    );
  }
  return context;
};
