import React, { useEffect, useState } from 'react';
import { Card, Image, Nav } from 'react-bootstrap';
import { getRelativePath } from 'helpers/extra';
import logoIcon from 'assets/img/logo_icon.svg';
import paths from 'routes/paths';
import { NavLink } from 'react-router-dom';

const organizationNavLinks = [
  {
    id: 1,
    title: 'Overview',
    link: paths.organizationDetailsOverview
  },
  {
    id: 2,
    title: 'License',
    link: paths.organizationDetailsLicense
  },
  {
    id: 3,
    title: 'Departments',
    link: paths.organizationDetailsDepartments
  },
  {
    id: 3,
    title: 'Notes',
    link: paths.organizationDetailsNotes
  }
];

const OrganizationNav = () => {
  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  return (
    <div
      className="font-sans-serif sticky-top"
      style={{ top: stickyStatus ? '5rem' : '0' }}
    >
      <Card>
        <Card.Body>
          <div className="mb-3">
            <Image src={logoIcon} height="24px" width="24px" />
            <span className="ms-2 fs--1 fw-medium text-900">
              Organization Profile
            </span>
          </div>
          <Nav variant="pills" className="flex-column">
            {organizationNavLinks.map(({ id, link, title }) => (
              <Nav.Item key={id}>
                <Nav.Link
                  as={NavLink}
                  className="fs--1 fw-medium patient-profile-nav"
                  to={getRelativePath(link, paths.organizationDetailsID)}
                >
                  {title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrganizationNav;
