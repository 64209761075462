import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PatientContextProvider } from 'context/PatientContext';
import PatientNav from './inner/PatientNav';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getRelativePath } from 'helpers/extra';
import paths from 'routes/paths';
import PatientProfile from './inner/PatientProfile';
import AppointmentHistory from './inner/AppointmentHistory';
import PatientNotes from './inner/PatientNotes';

const PatientLayout = () => {
  return (
    <PatientContextProvider>
      <Row className="g-3">
        <Col lg={3}>
          <PatientNav />
        </Col>
        <Col>
          <Routes>
            <Route
              path={getRelativePath(
                paths.patientDetailsOverview,
                paths.patientDetailsID
              )}
              element={<PatientProfile />}
            />
            <Route
              path={getRelativePath(
                paths.patientDetailsAppointmentsHistory,
                paths.patientDetailsID
              )}
              element={<AppointmentHistory />}
            />
            <Route
              path={getRelativePath(
                paths.patientDetailsAdminNotes,
                paths.patientDetailsID
              )}
              element={<PatientNotes />}
            />

            <Route
              path="/*"
              element={
                <Navigate
                  to={getRelativePath(
                    paths.patientDetailsOverview,
                    paths.patientDetailsID
                  )}
                  replace
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </PatientContextProvider>
  );
};

export default PatientLayout;
