import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import paths from 'routes/paths';

import AuthSimpleLayout from './AuthSimpleLayout';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';

import AppContext from 'context/Context';
import { RequireAuth } from 'react-auth-kit';
import PatientList from 'components/patients/PatientList';
import PatientLayout from 'components/patients/PatientLayout';
import RegisterPatient from 'components/patients/RegisterPatient';
import OrganizationList from 'components/organization/OrganizationList';
import OrganizationLayout from 'components/organization/OrganizationLayout';
import ProviderLayout from '../components/providers/ProviderLayout';
import ProviderList from '../components/providers/ProviderList';
import SystemUserList from '../components/system-users/SystemUserList';
import RegisterSystemUser from '../components/system-users/RegisterSystemUser';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" replace />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/*- ------------- simple ---------------------------  */}
        <Route element={<AuthSimpleLayout />}>
          <Route path={paths.login} element={<SimpleLogin />} />
          <Route path={paths.logout} element={<SimpleLogout />} />
          <Route
            path={paths.forgotPassword}
            element={<SimpleForgetPassword />}
          />
          <Route path={paths.resetPassword} element={<SimplePasswordReset />} />
          <Route path={paths.verifyEmail} element={<SimpleConfirmMail />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route
          element={
            <RequireAuth loginPath={paths.login}>
              <MainLayout />
            </RequireAuth>
          }
        >
          {/*Patient*/}
          <Route path={paths.patientList} element={<PatientList />}></Route>
          <Route
            path={paths.patientDetailsID + '/*'}
            element={<PatientLayout />}
          ></Route>
          <Route
            path={paths.registerNewPatient}
            element={<RegisterPatient />}
          ></Route>

          {/*Organization*/}
          <Route
            path={paths.organizationList}
            element={<OrganizationList />}
          ></Route>
          <Route
            path={paths.organizationDetailsID + '/*'}
            element={<OrganizationLayout />}
          ></Route>

          {/*Healthcare Providers*/}
          <Route path={paths.providerList} element={<ProviderList />}></Route>
          <Route
            path={paths.providerDetailsID + '/*'}
            element={<ProviderLayout />}
          ></Route>

          {/*System Users*/}
          <Route path={paths.systemUsers} element={<SystemUserList />}></Route>
          <Route
            path={paths.registerSystemUser}
            element={<RegisterSystemUser />}
          ></Route>
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
