import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getRelativePath } from 'helpers/extra';
import paths from 'routes/paths';
import { OrganizationContextProvider } from 'context/OrganizationContext';
import OrganizationNotes from './inner/OrganizationNotes';
import OrganizationLicense from './inner/OrganizationLicense';
import OrganizationProfile from './inner/OrganizationProfile';
import OrganizationDepartments from './inner/OrganizationDepartments';
import OrganizationNav from './inner/OrganizationNav';

const OrganizationLayout = () => {
  return (
    <OrganizationContextProvider>
      <Row className="g-3">
        <Col lg={3}>
          <OrganizationNav />
        </Col>
        <Col>
          <Routes>
            <Route
              path={getRelativePath(
                paths.organizationDetailsOverview,
                paths.organizationDetailsID
              )}
              element={<OrganizationProfile />}
            />
            <Route
              path={getRelativePath(
                paths.organizationDetailsLicense,
                paths.organizationDetailsID
              )}
              element={<OrganizationLicense />}
            />
            <Route
              path={getRelativePath(
                paths.organizationDetailsDepartments,
                paths.organizationDetailsID
              )}
              element={<OrganizationDepartments />}
            />
            <Route
              path={getRelativePath(
                paths.organizationDetailsNotes,
                paths.organizationDetailsID
              )}
              element={<OrganizationNotes />}
            />

            <Route
              path="/*"
              element={
                <Navigate
                  to={getRelativePath(
                    paths.organizationDetailsOverview,
                    paths.organizationDetailsID
                  )}
                  replace
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </OrganizationContextProvider>
  );
};

export default OrganizationLayout;
