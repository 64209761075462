import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  GET_HEALTHCARE_PROVIDER_BY_PK,
  GET_HEALTHCARE_PROVIDER_ORGANIZATIONS,
  GET_PROVIDER_NOTES,
  GET_SERVICE_PROVIDER_QUALIFICATION_DOCUMENTS_ID
} from 'models/query';
import LoadingContainer from 'components/common/LoadingContainer';

export const ProviderContext = createContext({
  providerDetails: {},
  providerOrganizations: [],
  providerNotes: [],
  providerDocuments: []
});

export const ProviderContextProvider = ({ children }) => {
  const { id } = useParams();

  const { data: providerDetails, loading: providerDetailsLoading } = useQuery(
    GET_HEALTHCARE_PROVIDER_BY_PK,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        service_provider_id: id
      }
    }
  );

  const { data: providerOrganizations, loading: providerOrganizationsLoading } =
    useQuery(GET_HEALTHCARE_PROVIDER_ORGANIZATIONS, {
      fetchPolicy: 'cache-and-network',
      variables: {
        service_provider_id: id
      }
    });

  const { data: providerNotes, loading: providerNotesLoading } = useQuery(
    GET_PROVIDER_NOTES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        service_provider_id: id
      }
    }
  );

  const { data: providerDocuments, loading: providerDocumentsLoading } =
    useQuery(GET_SERVICE_PROVIDER_QUALIFICATION_DOCUMENTS_ID, {
      fetchPolicy: 'cache-and-network',
      variables: {
        service_provider_id: id
      }
    });

  return (
    <ProviderContext.Provider
      value={{
        providerDetails: providerDetails?.service_providers_by_pk ?? {},
        providerOrganizations:
          providerOrganizations?.organization_service_provider ?? [],
        providerNotes: providerNotes?.service_provider_notes ?? [],
        providerDocuments:
          providerDocuments?.qualification_document_service_provider ?? []
      }}
    >
      {providerDetailsLoading &&
      providerOrganizationsLoading &&
      providerNotesLoading &&
      providerDocumentsLoading ? (
        <LoadingContainer />
      ) : (
        children
      )}
    </ProviderContext.Provider>
  );
};

ProviderContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useProviderContext = () => {
  const context = useContext(ProviderContext);
  if (context === undefined) {
    throw new Error(
      'useProviderContext must be used within a ProviderContextProvider'
    );
  }
  return context;
};
