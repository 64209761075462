import React, { useEffect, useState } from 'react';
import { GET_SYSTEM_USERS } from 'models/query';
import { useQuery } from '@apollo/client';
import { formatDate, getFullName, shortId } from 'helpers/extra';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import _ from 'lodash';
import LoadingContainer from 'components/common/LoadingContainer';
import ErrorContainer from 'components/common/ErrorContainer';
import SystemUserListHeader from './SystemUserListHeader';

const SystemUserList = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [systemUsersList, setSystemUsersList] = useState([]);

  const systemUsers = useQuery(GET_SYSTEM_USERS);

  useEffect(() => {
    if (systemUsers.data) {
      let array = [];
      systemUsers.data.government_employees.map(item => {
        array.push({
          id: item?.id,
          name: getFullName(item),
          avatar: item?.logo,
          phone: item?.phone,
          email: item?.email,
          created_at: item?.created_at
        });
      });
      setSystemUsersList(array);
    }
  }, [systemUsers.data]);

  if (systemUsers.loading) return <LoadingContainer />;
  if (systemUsers.error) return <ErrorContainer />;

  return (
    <>
      {systemUsersList.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns()}
          data={
            systemUsersList.length > 0
              ? _.filter(systemUsersList, function (v) {
                  return String(v)
                    .toLowerCase()
                    .includes(globalFilter.toLowerCase());
                })
              : []
          }
          selection
          sortable
          pagination
          perPage={14}
          rowCount={systemUsersList.length}
        >
          <Card>
            <Card.Header>
              <SystemUserListHeader
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'lg',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card>
          <FalconCardHeader title="Users List" titleTag="h5" />
          <Card.Body className="text-center">No user available.</Card.Body>
        </Card>
      )}
    </>
  );
};

const columns = () => [
  {
    accessor: 'id',
    Header: 'ID',
    Cell: rowData => {
      const { id } = rowData.row.original;
      return shortId(id);
    }
  },
  {
    accessor: 'name',
    Header: 'Name',
    Cell: rowData => {
      const { avatar, name } = rowData.row.original;

      return (
        <Flex alignItems="center">
          <Avatar src={avatar} name={name} size="xl" className="me-2" />
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'created_at',
    Header: 'Added In',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return formatDate(created_at);
    }
  }
];

export default SystemUserList;
