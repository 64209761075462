import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  GET_DEPARTMENT_DOCUMENTS,
  GET_ORGANIZATION_DEPARTMENT,
  GET_ORGANIZATION_NOTES,
  GET_ORGANIZATIONS_BY_PK
} from 'models/query';
import LoadingContainer from 'components/common/LoadingContainer';

export const OrganizationContext = createContext({
  organizationDetails: {},
  organizationNotes: [],
  departments: [],
  departmentDocuments: []
});

export const OrganizationContextProvider = ({ children }) => {
  const { id } = useParams();

  const { data: organizationDetails, loading: organizationDetailsLoading } =
    useQuery(GET_ORGANIZATIONS_BY_PK, {
      variables: {
        organization_id: id
      }
    });

  const {
    data: organizationDepartments,
    loading: organizationDepartmentsLoading
  } = useQuery(GET_ORGANIZATION_DEPARTMENT, {
    variables: {
      organization_id: id
    }
  });

  const { data: departmentDocuments, loading: departmentDocumentsLoading } =
    useQuery(GET_DEPARTMENT_DOCUMENTS, {
      variables: {
        organization_id: id
      }
    });

  const { data: organizationNotes, loading: organizationNotesLoading } =
    useQuery(GET_ORGANIZATION_NOTES, {
      variables: {
        organization_id: id
      }
    });

  return (
    <OrganizationContext.Provider
      value={{
        organizationDetails: organizationDetails?.organizations_by_pk ?? {},
        departments: organizationDepartments?.department_organization ?? [],
        departmentDocuments: departmentDocuments?.department_documents ?? [],
        organizationNotes: organizationNotes?.organization_notes ?? []
      }}
    >
      {organizationDetailsLoading &&
      organizationDepartmentsLoading &&
      departmentDocumentsLoading &&
      organizationNotesLoading ? (
        <LoadingContainer />
      ) : (
        children
      )}
    </OrganizationContext.Provider>
  );
};

OrganizationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useOrganizationContext = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useOrganizationContext must be used within a CommonDataContextProvider'
    );
  }
  return context;
};
