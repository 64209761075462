import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  GET_NEXT_OF_KIN,
  GET_PATIENT_BY_PK,
  GET_PATIENT_NOTES
} from 'models/query';
import LoadingContainer from 'components/common/LoadingContainer';

export const PatientContext = createContext({
  patientDetails: {},
  emergencyContacts: [],
  patientNotes: []
});

export const PatientContextProvider = ({ children }) => {
  const { id } = useParams();

  const { data: patientDetails, loading: patientDetailsLoading } = useQuery(
    GET_PATIENT_BY_PK,
    {
      skip: !id,
      variables: {
        id
      }
    }
  );

  const { data: patientNoK, loading: patientNoKLoading } = useQuery(
    GET_NEXT_OF_KIN,
    {
      skip: !id,
      variables: {
        patient_id: id
      }
    }
  );

  const { data: patientNotes, loading: patientNotesLoading } = useQuery(
    GET_PATIENT_NOTES,
    {
      skip: !id,
      variables: {
        patient_id: id
      }
    }
  );

  return (
    <PatientContext.Provider
      value={{
        patientDetails: patientDetails?.patients_by_pk ?? {},
        emergencyContacts: patientNoK?.next_of_kin ?? [],
        patientNotes: patientNotes?.patient_notes ?? []
      }}
    >
      {patientDetailsLoading && patientNoKLoading && patientNotesLoading ? (
        <LoadingContainer />
      ) : (
        children
      )}
    </PatientContext.Provider>
  );
};

PatientContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const usePatientContext = () => {
  const context = useContext(PatientContext);
  if (context === undefined) {
    throw new Error(
      'usePatientContext must be used within a CommonDataContextProvider'
    );
  }
  return context;
};
