import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { RESET_PASSWORD } from 'models/mutation';
import { useMutation } from '@apollo/client';
import LoadingButton from 'components/common/LoadingButton';

const PasswordResetForm = ({ hasLabel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const onSubmit = async e => {
    await resetPassword({
      variables: {
        email: e.email,
        password: e.password,
        password_confirmation: e.password_confirmation,
        token: e.token
      }
    })
      .then(data => console.log(data))
      .catch(error => console.log(error));
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          name="email"
          type="email"
          isInvalid={!!errors.email}
          {...register('email', { required: 'Email is required' })}
        />
        <Form.Control.Feedback tooltip>
          {errors && errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          name="password"
          type="password"
          isInvalid={!!errors.password}
          {...register('password', { required: 'Password is required' })}
        />
        <Form.Control.Feedback tooltip>
          {errors && errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          name="password_confirmation"
          type="password"
          isInvalid={!!errors.password_confirmation}
          {...register('password_confirmation', {
            required: 'Password confirmation is required'
          })}
        />
        <Form.Control.Feedback tooltip>
          {errors && errors.password_confirmation?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <LoadingButton
        type="submit"
        className="w-100"
        disabled={!errors}
        loading={loading}
      >
        Set password
      </LoadingButton>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
