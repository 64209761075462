import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import Flex from './Flex';
import React, { useEffect, useRef, useState } from 'react';

const LoadingButton = ({ children, loading, disabled, style, ...rest }) => {
  const ref = useRef();
  const [buttonWidth, setButtonWidth] = useState();
  const [buttonHeight, setButtonHeight] = useState();

  useEffect(() => {
    if (!loading) {
      setButtonWidth(ref.current.clientWidth);
      setButtonHeight(ref.current.clientHeight);
    }
  }, [loading, ref?.current?.clientWidth]);

  return (
    <Button
      ref={ref}
      disabled={loading || disabled}
      style={{
        ...style,
        ...(loading ? { width: buttonWidth, height: buttonHeight } : {})
      }}
      {...rest}
    >
      {loading ? (
        <Flex alignItems="center" justifyContent="center">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            // role="status"
            aria-hidden="true"
            // className="me-2"
          />
        </Flex>
      ) : (
        children
      )}
    </Button>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

export default LoadingButton;
