import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import refreshApi from './helpers/refreshApi';
import { AuthProvider } from 'react-auth-kit';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider
      authType={'cookie'}
      authName={'_auth'}
      refresh={refreshApi}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === 'https:'}
    >
      <Main>
        <App />
      </Main>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('main')
);
