import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getRelativePath } from 'helpers/extra';
import paths from 'routes/paths';
import ProviderNotes from './inner/ProviderNotes';
import ProviderProfile from './inner/ProviderProfile';
import ProviderQualifications from './inner/ProviderQualifications';
import ProviderNav from './inner/ProviderNav';
import ProviderOrganizations from './inner/ProviderOrganizations';
import { ProviderContextProvider } from '../../context/ProviderContext';

const ProviderLayout = () => {
  return (
    <ProviderContextProvider>
      <Row className="g-3">
        <Col lg={3}>
          <ProviderNav />
        </Col>
        <Col>
          <Routes>
            <Route
              path={getRelativePath(
                paths.providerDetailsOverview,
                paths.providerDetailsID
              )}
              element={<ProviderProfile />}
            />
            <Route
              path={getRelativePath(
                paths.providerDetailsQualifications,
                paths.providerDetailsID
              )}
              element={<ProviderQualifications />}
            />
            <Route
              path={getRelativePath(
                paths.providerDetailsOrganizations,
                paths.providerDetailsID
              )}
              element={<ProviderOrganizations />}
            />
            <Route
              path={getRelativePath(
                paths.providerDetailsNotes,
                paths.providerDetailsID
              )}
              element={<ProviderNotes />}
            />

            <Route
              path="/*"
              element={
                <Navigate
                  to={getRelativePath(
                    paths.providerDetailsOverview,
                    paths.providerDetailsID
                  )}
                  replace
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </ProviderContextProvider>
  );
};

export default ProviderLayout;
