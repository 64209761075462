import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useOrganizationContext } from 'context/OrganizationContext';
import { formatDate } from '../../../helpers/extra';
import { Link } from 'react-router-dom';
import paths from '../../../routes/paths';

const OrganizationLicenseShort = () => {
  const { organizationDetails } = useOrganizationContext();
  const [licenseDetails, setLicenseDetails] = useState([]);

  useEffect(() => {
    if (organizationDetails) {
      let array = [];
      array.push({
        title: 'Status',
        value: organizationDetails?.license_status
      });
      array.push({
        title: 'Start Date',
        value: organizationDetails?.license_start_date
          ? formatDate(organizationDetails?.license_start_date)
          : ''
      });
      array.push({
        title: 'End Date',
        value: organizationDetails?.license_end_date
          ? formatDate(organizationDetails?.license_end_date)
          : ''
      });
      setLicenseDetails(array);
    }
  }, [organizationDetails]);

  return (
    <Card className="p-2">
      <FalconCardHeader
        title={<h4 className="fw-semi-bold">Organization License</h4>}
        light={false}
        titleTag="div"
        className="pb-1"
        menuClassName="align-items-start"
        endEl={
          <Link
            to={
              paths.organizationDetails + `/${organizationDetails.id}/license`
            }
          >
            Edit
          </Link>
        }
      />
      <Card.Body className="pt-2 pb-3">
        <Table size="sm" borderless className="font-sans-serif my-0 w-100">
          <tbody>
            {licenseDetails?.map(detail => (
              <tr key={detail.id}>
                <td className="text-start ps-0 text-capitalize">
                  {detail.title}
                </td>
                <td className="text-start text-capitalize">{detail.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default OrganizationLicenseShort;
