import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Col, Form, Row } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { UPDATE_ORGANIZATION_LICENSE } from 'models/mutation';
import { GET_ORGANIZATIONS_BY_PK } from 'models/query';
import { useOrganizationContext } from 'context/OrganizationContext';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import OrganizationLicenseShort from './OrganizationLicenseShort';

const OrganizationLicense = () => {
  const { organizationDetails } = useOrganizationContext();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const [insertNote, { loading }] = useMutation(UPDATE_ORGANIZATION_LICENSE, {
    refetchQueries: [
      {
        query: GET_ORGANIZATIONS_BY_PK,
        variables: { organization_id: organizationDetails.id },
        fetchPolicy: 'network-only'
      }
    ],
    onCompleted: () => toast.success('License updated', { theme: 'colored' }),
    onError: () =>
      toast.error('Something went wrong! try again', { theme: 'colored' })
  });

  useEffect(() => {
    if (organizationDetails.license_status) {
      setValue('end_date', organizationDetails.license_end_date);
      setValue('start_date', organizationDetails.license_start_date);
      setValue('status', organizationDetails.license_status);
    }
  }, [organizationDetails]);

  const onSubmit = async event => {
    await insertNote({
      variables: {
        license_end_date: event.end_date,
        license_start_date: event.start_date,
        license_status: event.status,
        organization_id: organizationDetails.id
      }
    });
  };

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={<h4 className="fw-semi-bold">License</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />
        <Card.Body className="pt-0">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3">
              <Form.Group as={Col} lg={6}>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  isInvalid={!!errors.start_date}
                  {...register('start_date', {
                    required: 'Start is required!'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors && errors.start_date?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  isInvalid={!!errors.end_date}
                  {...register('end_date', {
                    required: 'End Date is required!'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors && errors.end_date?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={12}>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  isInvalid={!!errors.status}
                  {...register('status', {
                    required: 'Status is required!'
                  })}
                >
                  <option value="">Select</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors && errors.status?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <LoadingButton
                  type="submit"
                  color="primary"
                  className="w-100"
                  disabled={!errors}
                  loading={loading}
                >
                  Update
                </LoadingButton>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <OrganizationLicenseShort />
    </>
  );
};

export default OrganizationLicense;
