import React from 'react';
import FalconCardHeader from '../../common/FalconCardHeader';
import { Alert, Card, Image } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import { useProviderContext } from 'context/ProviderContext';
import 'react-medium-image-zoom/dist/styles.css';

const ProviderQualifications = () => {
  const { providerDocuments } = useProviderContext();

  return (
    <Card>
      <FalconCardHeader title="Documents" />
      <Card.Body className="bg-light">
        {providerDocuments.length ? (
          <>
            {providerDocuments.map(item => (
              <div key={item.id} className="my-1 d-flex align-items-center">
                <Zoom>
                  <Image width="50" src={item.qualification_document.url} />
                </Zoom>
                <div className="d-flex flex-column">
                  <div className="ms-3 me-auto">
                    {item.qualification_document.name}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="p-3 text-center d-flex align-items-center justify-content-center">
            <Alert variant="danger">No documents available.</Alert>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProviderQualifications;
