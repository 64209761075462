import React, { useEffect, useState } from 'react';
import { GET_HEALTHCARE_PROVIDERS } from 'models/query';
import { useQuery } from '@apollo/client';
import { formatDate, getFullName, shortId } from 'helpers/extra';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import _ from 'lodash';
import LoadingContainer from 'components/common/LoadingContainer';
import ErrorContainer from 'components/common/ErrorContainer';
import ProviderListHeader from './ProviderListHeader';

const ProviderList = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [providerList, setProviderList] = useState([]);

  const providers = useQuery(GET_HEALTHCARE_PROVIDERS);

  useEffect(() => {
    if (providers.data) {
      let array = [];
      providers.data.service_providers.map(item => {
        array.push({
          id: item?.id,
          name: getFullName(item),
          avatar: item?.avatar,
          phone: item?.phone,
          email: item?.email,
          role: item?.role?.name,
          created_at: formatDate(item?.created_at)
        });
      });
      setProviderList(array);
    }
  }, [providers.data]);

  if (providers.loading) return <LoadingContainer />;
  if (providers.error) return <ErrorContainer />;

  return (
    <>
      {providerList.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns()}
          data={
            providerList.length > 0
              ? _.filter(providerList, function (v) {
                  return String(v)
                    .toLowerCase()
                    .includes(globalFilter.toLowerCase());
                })
              : []
          }
          selection
          sortable
          pagination
          perPage={14}
          rowCount={providerList.length}
        >
          <Card>
            <Card.Header>
              <ProviderListHeader
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'lg',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card>
          <FalconCardHeader title="Healthcare Providers List" titleTag="h5" />
          <Card.Body className="text-center">
            No healthcare provider available.
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const columns = () => [
  {
    accessor: 'id',
    Header: 'ID',
    Cell: rowData => {
      const { id } = rowData.row.original;
      return shortId(id);
    }
  },
  {
    accessor: 'name',
    Header: 'Name',
    Cell: rowData => {
      const { id, avatar, name } = rowData.row.original;

      return (
        <Link to={paths.providerDetails + `/${id}`}>
          <Flex alignItems="center">
            <Avatar src={avatar} name={name} size="xl" className="me-2" />
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'role',
    Header: 'Role'
  },
  {
    accessor: 'created_at',
    Header: 'Added In'
  }
  // {
  //   accessor: 'status',
  //   Header: 'Status',
  //   Cell: rowData => {
  //     const { status } = rowData.row.original;
  //     return status ? (
  //       <span className="text-success">Active</span>
  //     ) : (
  //       <span className="text-danger">Inactive</span>
  //     );
  //   }
  // }
];

export default ProviderList;
