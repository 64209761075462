import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  useAccordionButton
} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { calculateAge, formatDate, getIdByName } from 'helpers/extra';
import { useCommonDataContext } from 'context/CommonDataContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { MdArrowDropDown } from 'react-icons/md';
import PropTypes from 'prop-types';
import FalconDropzone from 'components/common/FalconDropzone';
import Avatar from 'components/common/Avatar';
import { isIterableArray } from 'helpers/utils';
import avatarImg from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import LoadingButton from '../common/LoadingButton';
import generator from 'generate-password';
import { GET_PATIENTS, SEARCH_ID_CARD } from 'models/query';
import {
  GENERATE_SINGLE_ID_CARD,
  INSERT_PATIENT_AVATAR,
  INSERT_PATIENTS,
  UPDATE_ID_CARD
} from 'models/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { errorMessage } from 'config';
import InputPlaceholder from 'components/common/InputPlaceholder';
import IconButton from 'components/common/IconButton';

const RegisterPatient = () => {
  const navigate = useNavigate();
  const { languages, relations, nationalities, countries } =
    useCommonDataContext();
  const [kinActive, setKinActive] = useState(false);
  const [kin2Active, setKin2Active] = useState(false);
  const [avatar, setAvatar] = useState([]);
  const [healthIdMessage, setHealthIdMessage] = useState({});
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors }
  } = useForm();

  const [insertPatient, { loading: insertPatientLoading }] = useMutation(
    INSERT_PATIENTS,
    {
      refetchQueries: [{ query: GET_PATIENTS }]
    }
  );

  const [insertPatientAvatar, { loading: insertPatientAvatarLoading }] =
    useMutation(INSERT_PATIENT_AVATAR, {
      onError: error => {
        toast.error(error, { theme: 'colored' });
      }
    });

  const [generateIdCard, { loading: generateIdCardLoading }] = useMutation(
    GENERATE_SINGLE_ID_CARD,
    {
      onCompleted: data =>
        setValue('health_id', data.generateSingleIDCard.card_no),
      onError: () => toast.error(errorMessage, { theme: 'colored' })
    }
  );

  const [updateIdCard] = useMutation(UPDATE_ID_CARD, {
    onError: () => toast.error(errorMessage, { theme: 'colored' })
  });

  const [searchIdCard, { loading: searchIdCardLoading }] = useLazyQuery(
    SEARCH_ID_CARD,
    {
      onCompleted: data => {
        if (data.id_cards.length > 0) {
          if (data.id_cards.status === 'Active') {
            setHealthIdMessage({
              icon: faExclamationTriangle,
              message: 'This ID number has already been taken',
              type: 'warning'
            });
          } else {
            setHealthIdMessage({
              icon: 'check',
              message: 'This ID number is available',
              type: 'success'
            });
          }
        } else {
          setHealthIdMessage({
            icon: faExclamationTriangle,
            message: 'This ID number is not correct',
            type: 'danger'
          });
        }
      },
      onError: () => toast.error(errorMessage, { theme: 'colored' })
    }
  );

  const searchIdCardRes = async () => {
    return await searchIdCard({ variables: { card_no: watch('health_id') } });
  };

  useEffect(() => {
    setValue('language', getIdByName('Somali', languages));
    setValue('nationality', getIdByName('Somalian', nationalities));
    setValue('country', getIdByName('Somalia', countries));
  }, [languages, nationalities, countries]);

  useEffect(() => {
    setHealthIdMessage({});
    if (watch('health_id').length >= 8) {
      searchIdCardRes().then();
    }
  }, [watch('health_id')]);

  const onSubmit = async event => {
    console.log(event);
    let kins = [];
    if (kinActive) {
      /* -------------- First Kin -------------- */
      kins.push({
        first_name: event.kinFirstName,
        middle_name: event.kinMiddleName,
        last_name: event.kinLastName,
        relation_id: event.kinRelation,
        phone_number: event.kinPhone.replace(/\D/g, ''),
        other_relation: 'none', // FIXME: Need to remove this from DB first
        email: 'next_of_kin@fabmedic.io', // FIXME: Need to remove this from DB first
        gender: 'Male' // FIXME: Need to remove this from DB first
      });
    }
    if (kin2Active) {
      /* -------------- Second Kin -------------- */
      kins.push({
        first_name: event.kin2FirstName,
        middle_name: event.kin2MiddleName,
        last_name: event.kin2LastName,
        relation_id: event.kin2Relation,
        phone_number: event.kin2Phone.replace(/\D/g, ''),
        other_relation: 'none', // FIXME: Need to remove this from DB first
        email: 'next_of_kin@fabmedic.io', // FIXME: Need to remove this from DB first
        gender: 'Male' // FIXME: Need to remove this from DB first
      });
    }
    try {
      const response = await insertPatient({
        variables: {
          first_name: event.firstName,
          middle_name: event.middleName,
          last_name: event.lastName,
          user_name:
            event.firstName.toLowerCase() + '_' + event.lastName.toLowerCase(),
          email: event.email,
          phone: event.phone.replace(/\D/g, ''),
          gender: event.gender,
          marital_status: event.maritalStatus,
          language_id: event.language,
          nationality_id: event.nationality,
          title: event.title,
          new_born: event.newBorn,
          date_of_birth: formatDate(event.dob, '-', 'yyyy-MM-dd'),
          address: event.address,
          province: event.province,
          district: event.district,
          country_id: event.country,
          post_code: event.post_code,
          age_bracket: String(calculateAge(event.dob)),
          password: event.temp_password,
          // staff_id: user.id, // TODO: We need service_provider Id to know who created the ticket
          avatar: avatar[0]?.base64,
          // organization_id: selected_organization
          //   ? selected_organization
          //   : user.organization_id,
          id_card_number: event.health_id,
          hospital_number: event.health_id,
          registered_from_mobile: false,
          next_of_kins: kins
        }
      });
      if (response.data.insert_patients.affected_rows) {
        const id = response.data.insert_patients.returning[0].id;
        if (id) {
          await insertPatientAvatar({
            variables: { base64str: avatar[0]?.base64, patient_id: id }
          });
          await updateIdCard({
            variables: {
              card_no: event.health_id,
              status: 'Active',
              used_date: formatDate(new Date(), '-', 'yyyy-MM-dd')
            }
          });
          toast.success('Patient Created', {
            theme: 'colored'
          });
          document.getElementById('patientRegistrationForm').reset();
          navigate(paths.patientList);
        }
      }
    } catch (e) {
      let message = e.message;
      if (e.message.includes('patients_phone_key')) {
        message =
          'Patient with same phone exists! Please use a different phone.';
      } else {
        message = errorMessage;
      }
      toast.error(message, { theme: 'error' });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="patientRegistrationForm">
      <Card className="mb-3">
        <FalconCardHeader title="Profile Information" />
        <Card.Body className="fs--1">
          <Form.Group as={Col} lg={8}>
            {searchIdCardLoading || generateIdCardLoading ? (
              <InputPlaceholder label="My Health ID Number" />
            ) : (
              <>
                <Form.Label>My Health ID Number</Form.Label>
                <InputGroup className="mb-3 align-items-start flex-nowrap">
                  <div className="flex-1">
                    <Form.Control
                      type="number"
                      isInvalid={!!errors.health_id}
                      {...register('health_id', {
                        required: 'Health ID is required!',
                        minLength: {
                          value: 8,
                          message: 'Minimum 8 digit required'
                        }
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.health_id && errors.health_id.message}
                    </Form.Control.Feedback>
                  </div>
                  <Button
                    variant="secondary"
                    onClick={async () => await generateIdCard()}
                  >
                    Generate ID Number
                  </Button>
                </InputGroup>
              </>
            )}
          </Form.Group>

          {healthIdMessage && (
            <Flex
              className={`text-${healthIdMessage.type} gap-2 fs-1 align-items-center mb-3`}
            >
              <FontAwesomeIcon icon={healthIdMessage.icon} />
              <h6 className="mb-0">{healthIdMessage.message}</h6>
            </Flex>
          )}

          <Row className="mb-3">
            <Col lg={12}>
              <div className="border-1 border-300 border rounded">
                <Row className="p-3 flex-between-center">
                  <Col md="auto" className="flex-center d-flex gap-3">
                    <Avatar
                      size="3xl"
                      className="border border-1 border-300 rounded-circle"
                      src={
                        avatar.length > 0
                          ? isIterableArray(avatar)
                            ? avatar[0]?.base64 || avatar[0]?.src
                            : ''
                          : avatarImg
                      }
                    />
                    <span className="fw-semi-bold">Patient Personal Photo</span>
                  </Col>
                  <Col md="auto">
                    <FalconDropzone
                      files={avatar}
                      onChange={files => {
                        setAvatar(files);
                      }}
                      multiple={false}
                      accept="image/*"
                      className="w-auto h-auto p-0 border-0 d-inline-block"
                      placeholder={
                        <>
                          <IconButton
                            type="button"
                            icon="check"
                            iconAlign="left"
                          >
                            Upload
                          </IconButton>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="g-3">
            <Form.Group as={Col} md={6} lg={4}>
              <Form.Label>
                First Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.firstName}
                {...register('firstName', {
                  required: 'First name field is required'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName && errors.firstName.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6} lg={4}>
              <Form.Label>Second Name</Form.Label>
              <Form.Control type="text" {...register('middleName')} />
            </Form.Group>
            <Form.Group as={Col} md={6} lg={4}>
              <Form.Label>
                Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.lastName}
                {...register('lastName', {
                  required: 'Last name field is required'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName && errors.lastName.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6} className="d-flex flex-column">
              <Form.Label className="d-flex align-items-center justify-content-between mb-1">
                <span>
                  D.O.B <span className="text-danger">*</span>
                </span>
                <Form.Check
                  type="switch"
                  id="defaultCheckbox"
                  label="New Born"
                  className="mb-0"
                  {...register('newBorn', {
                    onChange: e => {
                      if (e.target.checked) {
                        setValue('dob', new Date());
                      }
                    }
                  })}
                />
              </Form.Label>
              <Controller
                name="dob"
                control={control}
                errors={errors}
                isInvalid={!!errors.dob}
                rules={{
                  required: 'Date of birth is required'
                }}
                render={({ field }) => (
                  <>
                    <DatePicker
                      className={
                        errors.dob && errors.dob.message
                          ? 'is-invalid form-control'
                          : 'form-control'
                      }
                      selected={field.value}
                      timeIntervals={5}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
                      onChange={date => field.onChange(date)}
                      formatWeekDay={day => day.slice(0, 3)}
                      placeholderText="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dob && errors.dob.message}
                    </Form.Control.Feedback>
                  </>
                )}
              />
            </Form.Group>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-between fs-0 pt-md-4"
              style={{ fontWeight: '600' }}
            >
              <span>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="me-2"
                ></FontAwesomeIcon>
                Age
              </span>
              <span>
                {watch('dob')
                  ? calculateAge(watch('dob')) + ' Years Old'
                  : '0 Years Old'}
              </span>
            </Col>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                Gender <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                isInvalid={!!errors.gender}
                {...register('gender', {
                  required: 'Gender is required'
                })}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.gender && errors.gender.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Marital Status</Form.Label>
              <Form.Select {...register('maritalStatus')}>
                <option value="">Select</option>
                <option value="Married">Married</option>
                <option value="Single">Single</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>
            {!nationalities.length > 0 ? (
              <InputPlaceholder props={{ md: 6 }} label="Nationality" />
            ) : (
              <Form.Group as={Col} md={6}>
                <Form.Label>Nationality</Form.Label>
                <Form.Select {...register('nationality')}>
                  <option value="">Select</option>
                  {nationalities.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            {!languages.length > 0 ? (
              <InputPlaceholder props={{ md: 6 }} label="Language" />
            ) : (
              <Form.Group as={Col} md={6}>
                <Form.Label>Language</Form.Label>
                <Form.Select {...register('language')}>
                  <option value="">Select</option>
                  {languages.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Fabmedic Mobile App Access" />
        <Card.Body className="fs--1 ">
          <Row className="g-3">
            <Form.Group as={Col} md={6}>
              <Form.Label>
                Phone <span className="text-danger">*</span>
              </Form.Label>
              <Controller
                name="phone"
                control={control}
                errors={errors}
                rules={{
                  required: 'Phone is required',
                  min: {
                    value: 1000000000,
                    message: 'Phone must be valid'
                  },
                  max: {
                    value: 999999999999,
                    message: 'Phone must be valid'
                  }
                }}
                render={({ field }) => (
                  <>
                    <PhoneInput
                      alwaysDefaultMask="true"
                      country={'so'}
                      isInvalid={!!errors.phone}
                      enableSearch="true"
                      className={
                        errors.phone && errors.phone.message ? 'is-invalid' : ''
                      }
                      onChange={phone => field.onChange(phone)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone && errors.phone.message}
                    </Form.Control.Feedback>
                  </>
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md={8}>
              <Form.Label>Temporary Access Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  isInvalid={!!errors.temp_password}
                  {...register('temp_password', {
                    required: 'Temporary password is required!'
                  })}
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    const password = generator.generate({
                      length: 10,
                      numbers: true
                    });
                    setValue('temp_password', password);
                  }}
                >
                  Generate
                </Button>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.temp_password && errors.temp_password.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Contact Email" />
        <Card.Body className="fs--1 ">
          <Row className="g-3">
            <Form.Group as={Col} md={6}>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                isInvalid={!!errors.email}
                {...register('email', {
                  pattern: {
                    value:
                      /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                    message: 'Email must be valid'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Address" />
        <Card.Body className="fs--1 ">
          <Row className="g-3">
            <Form.Group as={Col} md={6}>
              <Form.Label>Country</Form.Label>
              <Form.Select {...register('country')}>
                <option>Select</option>
                {countries.length > 0 &&
                  countries.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>State/Province</Form.Label>
              <Form.Control type="text" {...register('province')} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Address/Village</Form.Label>
              <Form.Control type="text" {...register('address')} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Zip/Post Code</Form.Label>
              <Form.Control type="text" {...register('post_code')} />
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Accordion alwaysOpen>
        <Card className="mb-3">
          <Card.Header>
            <CustomToggle eventKey="0" action={setKinActive} state={kinActive}>
              <>
                <h5>Emergency Contact 1</h5>
                <MdArrowDropDown className="text-900 fs-3" />
              </>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row className="g-3">
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Relative First Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.kinFirstName}
                    {...register('kinFirstName', {
                      validate: {
                        required: value => {
                          if (!value && kinActive)
                            return 'First name field is required';
                          return true;
                        }
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kinFirstName && errors.kinFirstName.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Relative Middle Name</Form.Label>
                  <Form.Control type="text" {...register('kinMiddleName')} />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Relative Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.kinLastName}
                    {...register('kinLastName', {
                      validate: {
                        required: value => {
                          if (!value && kinActive)
                            return 'Last name field is required';
                          return true;
                        }
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kinLastName && errors.kinLastName.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Phone <span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    name="kinPhone"
                    control={control}
                    errors={errors}
                    rules={{
                      validate: {
                        required: value => {
                          if (!value && kinActive)
                            return 'Phone field is required';
                          return true;
                        }
                      },
                      min: {
                        value: 1000000000,
                        message: 'Phone must be valid'
                      },
                      max: {
                        value: 999999999999,
                        message: 'Phone must be valid'
                      }
                    }}
                    render={({ field }) => (
                      <>
                        <PhoneInput
                          alwaysDefaultMask="true"
                          country={'so'}
                          isInvalid={!!errors.kinPhone}
                          enableSearch="true"
                          className={
                            errors.kinPhone && errors.kinPhone.message
                              ? 'is-invalid'
                              : ''
                          }
                          onChange={phone => field.onChange(phone)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.kinPhone && errors.kinPhone.message}
                        </Form.Control.Feedback>
                      </>
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Specify relation to patient
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    isInvalid={!!errors.kinRelation}
                    {...register('kinRelation', {
                      validate: {
                        required: value => {
                          if (!value && kinActive)
                            return 'Relation field is required';
                          return true;
                        }
                      }
                    })}
                  >
                    <option value="">Select</option>
                    {relations &&
                      relations.map(relation => (
                        <option key={relation.id} value={relation.id}>
                          {relation.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.kinRelation && errors.kinRelation.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="mb-3">
          <Card.Header>
            <CustomToggle
              eventKey="1"
              action={setKin2Active}
              state={kin2Active}
            >
              <>
                <h5>Emergency Contact 2</h5>
                <MdArrowDropDown className="text-900 fs-3" />
              </>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Row className="g-3">
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Relative First Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.kin2FirstName}
                    {...register('kin2FirstName', {
                      validate: {
                        required: value => {
                          if (!value && kin2Active)
                            return 'First name field is required';
                          return true;
                        }
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kin2FirstName && errors.kin2FirstName.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Relative Middle Name</Form.Label>
                  <Form.Control type="text" {...register('kin2MiddleName')} />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Relative Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.kin2LastName}
                    {...register('kin2LastName', {
                      validate: {
                        required: value => {
                          if (!value && kin2Active)
                            return 'Last name field is required';
                          return true;
                        }
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kin2LastName && errors.kin2LastName.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Phone <span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    name="kin2Phone"
                    control={control}
                    errors={errors}
                    rules={{
                      validate: {
                        required: value => {
                          if (!value && kin2Active)
                            return 'Phone field is required';
                          return true;
                        }
                      },
                      min: {
                        value: 1000000000,
                        message: 'Phone must be valid'
                      },
                      max: {
                        value: 999999999999,
                        message: 'Phone must be valid'
                      }
                    }}
                    render={({ field }) => (
                      <>
                        <PhoneInput
                          alwaysDefaultMask="true"
                          country={'so'}
                          isInvalid={!!errors.kin2Phone}
                          enableSearch="true"
                          className={
                            errors.kin2Phone && errors.kin2Phone.message
                              ? 'is-invalid'
                              : ''
                          }
                          onChange={phone => field.onChange(phone)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.kin2Phone && errors.kin2Phone.message}
                        </Form.Control.Feedback>
                      </>
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    Specify relation to patient{' '}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    isInvalid={!!errors.kin2Relation}
                    {...register('kin2Relation', {
                      validate: {
                        required: value => {
                          if (!value && kin2Active)
                            return 'Relation field is required';
                          return true;
                        }
                      }
                    })}
                  >
                    <option value="">Select</option>
                    {relations &&
                      relations.map(relation => (
                        <option key={relation.id} value={relation.id}>
                          {relation.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.kin2Relation && errors.kin2Relation.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Card>
        <Card.Body className="p-4">
          <Row className="g-2">
            <Col xs={12} className="text-end">
              <LoadingButton
                type="submit"
                variant="primary"
                className="w-100 w-sm-auto"
                size="lg"
                disabled={!errors}
                loading={insertPatientLoading || insertPatientAvatarLoading}
              >
                Create Patient
              </LoadingButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

const CustomToggle = ({ children, eventKey, action, state }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    <Button
      variant="link"
      className="p-0 shadow-none w-100 text-decoration-none"
      onClick={() => {
        decoratedOnClick();
        action(!state);
      }}
    >
      <div className="d-flex text-900 justify-content-between">{children}</div>
    </Button>
  );
};

CustomToggle.propTypes = {
  children: PropTypes.object,
  eventKey: PropTypes.string,
  action: PropTypes.func,
  state: PropTypes.bool
};

export default RegisterPatient;
