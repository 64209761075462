import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';
import { useOrganizationContext } from 'context/OrganizationContext';
import { getNameById } from 'helpers/extra';
import { useCommonDataContext } from 'context/CommonDataContext';
import { AiOutlineAlignLeft } from 'react-icons/ai';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { RiAttachment2 } from 'react-icons/ri';
import Divider from 'components/common/Divider';
import Zoom from 'react-medium-image-zoom';
import LoadingButton from 'components/common/LoadingButton';
import { useMutation } from '@apollo/client';
import { UPDATE_DEPARTMENT_STATUS } from 'models/mutation';
import { GET_ORGANIZATION_DEPARTMENT } from 'models/query';
import { toast } from 'react-toastify';
import { errorMessage } from 'config';
import { useParams } from 'react-router-dom';

const OrganizationDepartmentReview = ({ department_id, setSelected }) => {
  const { id } = useParams();
  const { departmentDocuments, departments } = useOrganizationContext();
  const { departments: allDepartments } = useCommonDataContext();
  const docs = departmentDocuments?.filter(
    x => x.department_id === department_id
  );

  const [approveDepartment, { loading: approveDepartmentLoading }] =
    useMutation(UPDATE_DEPARTMENT_STATUS, {
      refetchQueries: [{ query: GET_ORGANIZATION_DEPARTMENT }],
      onCompleted: () => {
        toast.success('Department Approved', { theme: 'colored' });
        setSelected('');
      },
      onError: () => toast.error(errorMessage, { theme: 'colored' })
    });

  return (
    <>
      <Card className="mb-3 p-3">
        <Row className="align-items-center g-0">
          <Col md="auto" className="ps-3">
            <BsFillArrowLeftCircleFill
              className="text-primary"
              size={30}
              onClick={() => setSelected('')}
            />
          </Col>
          <Col md="auto">
            <FalconCardHeader
              title={
                <h5 className="fw-semi-bold">
                  {getNameById(department_id, allDepartments)}
                </h5>
              }
              light={false}
              titleTag="div"
              className="pb-2"
              menuClassName="align-items-start"
            />
          </Col>
        </Row>
      </Card>
      <Card className="mb-3 p-3">
        <Row className="align-items-center g-0">
          <Col md="auto" className="ps-3">
            <span
              className="bg-200 rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: '40px', height: '40px' }}
            >
              <AiOutlineAlignLeft className="text-primary" size={20} />
            </span>
          </Col>
          <Col md="auto">
            <FalconCardHeader
              title={<h5>Description</h5>}
              light={false}
              titleTag="div"
              className="pb-2"
              menuClassName="align-items-start"
            />
          </Col>
        </Row>
        <Divider />
        <Card.Body className="pt-0">
          <p className="mt-2 mb-4">
            {allDepartments.find(x => x.id === department_id)?.description}
          </p>
          <h5 className="fw-semi-bold mt-3">Attachments</h5>
          <Row className="align-items-center g-0 pt-2">
            <Col md="auto">
              <span
                className="bg-200 rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: '40px', height: '40px' }}
              >
                <RiAttachment2 className="text-primary" size={20} />
              </span>
            </Col>
            <Col md="auto">
              <FalconCardHeader
                title={<h5>Sent Files, Documents</h5>}
                light={false}
                titleTag="div"
                className="pb-2"
                menuClassName="align-items-start"
              />
            </Col>
            <Col xs={12} className="mt-3">
              <Row className="g-3">
                {docs.length > 0 ? (
                  docs?.map((item, index) => (
                    <Col key={index} lg={6}>
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-between my-1"
                      >
                        <Zoom>
                          <Image width="50" src={item.url} />
                        </Zoom>
                        <span className="ms-3 me-auto">{item.name}</span>
                      </div>
                    </Col>
                  ))
                ) : (
                  <>No Documents Found!</>
                )}
              </Row>
            </Col>
            {departments.find(x => x.department_id === department_id)
              ?.government_status === 'inactive' && (
              <Col xs={12} className="mt-3 text-end">
                <LoadingButton
                  onClick={async () =>
                    await approveDepartment({
                      variables: {
                        department_id,
                        organization_id: id,
                        status: 'active'
                      }
                    })
                  }
                  loading={approveDepartmentLoading}
                  disabled={approveDepartmentLoading}
                >
                  Approve
                </LoadingButton>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

OrganizationDepartmentReview.propTypes = {
  department_id: PropTypes.string,
  setSelected: PropTypes.func
};

export default OrganizationDepartmentReview;
