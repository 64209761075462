import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { formatDate } from 'helpers/extra';
import { useOrganizationContext } from 'context/OrganizationContext';
import Avatar from '../../common/Avatar';
import OrganizationLicenseShort from './OrganizationLicenseShort';

const OrganizationProfile = () => {
  const { organizationDetails } = useOrganizationContext();
  const [organizedDetails, setOrganizedDetails] = useState([]);

  useEffect(() => {
    if (organizationDetails) {
      let array = [];
      array.push({
        title: 'Entity Name',
        value: organizationDetails.entity_name
      });
      array.push({ title: 'ID', value: organizationDetails.id });
      array.push({
        title: 'Address',
        value: organizationDetails.address
      });
      array.push({ title: 'Phone Number', value: organizationDetails.phone });
      array.push({ title: 'Email Address', value: organizationDetails.email });
      array.push({
        title: 'Registered At',
        value: formatDate(organizationDetails.created_at)
      });
      setOrganizedDetails(array);
    }
  }, [organizationDetails]);

  return (
    <>
      <Card className="mb-3 p-2">
        <FalconCardHeader
          title={<h4 className="fw-semi-bold">Profile</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />
        <Card.Body className="pt-0">
          <Row>
            <Col md={12}>
              <Avatar
                size="4xl"
                name={organizationDetails?.entity_name}
                src={organizationDetails.logo}
              />
            </Col>
            <Col md={12}>
              <Table
                size="sm"
                borderless
                className="font-sans-serif my-0 w-100"
              >
                <tbody>
                  {organizedDetails?.map(detail => (
                    <tr key={detail.id}>
                      <td className="text-start ps-0">{detail.title}</td>
                      <td className="text-start">{detail.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <OrganizationLicenseShort />
    </>
  );
};

export default OrganizationProfile;
