export const getIdByName = (name, array) => {
  return array.find(x => x.name === name)?.id;
};

export const getNameById = (id, array) => {
  return array.find(x => x.id === id)?.name;
};

export const getStatusById = (id, array) => {
  return array.find(x => x.id === id)?.status;
};

export const getFullName = object => {
  return (
    object?.first_name +
    (object?.middle_name ? ' ' + object?.middle_name + ' ' : ' ') +
    object?.last_name
  );
};

export const calculateAge = birthDate => {
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
};

export const shortId = id => {
  return id ? id.substr(0, id.indexOf('-')) : false;
};

export const getRelativePath = (path, basePath) => {
  const pathArray = path.split('/');
  const basePathArray = basePath.split('/');
  const relativePathArray = pathArray.slice(basePathArray.length);
  return relativePathArray.join('/');
};

export const parseRouteParams = (route, params = {}, searchParams = {}) => {
  let parsedRoute = route;
  Object.keys(params).forEach(key => {
    parsedRoute = parsedRoute.replace(`:${key}`, params[key]);
  });
  Object.keys(searchParams).forEach((key, index) => {
    if (searchParams[key] !== null && searchParams[key] !== undefined) {
      parsedRoute =
        parsedRoute + (index === 0 ? '?' : '&') + `${key}=${searchParams[key]}`;
    }
  });
  return parsedRoute;
};

export const categorizePerson = date => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  if (age < 18) {
    return 'Child';
  } else if (age >= 18 && age < 60) {
    return 'Adult';
  } else {
    return 'Elder';
  }
};

export const formatDate = (date, separator = '-', format = 'dd-MM-yyyy') => {
  let newDate = new Date(date);
  if (format === 'yyyy-MM-dd') {
    return (
      newDate.getFullYear() +
      separator +
      (newDate.getMonth() > 8
        ? newDate.getMonth() + 1
        : '0' + (newDate.getMonth() + 1)) +
      separator +
      (newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate())
    );
  } else if (format === 'MM-dd-yyyy') {
    return (
      (newDate.getMonth() > 8
        ? newDate.getMonth() + 1
        : '0' + (newDate.getMonth() + 1)) +
      separator +
      (newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate()) +
      separator +
      newDate.getFullYear()
    );
  } else if (format === 'dd-MM-yyyy') {
    return (
      (newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate()) +
      separator +
      (newDate.getMonth() > 8
        ? newDate.getMonth() + 1
        : '0' + (newDate.getMonth() + 1)) +
      separator +
      newDate.getFullYear()
    );
  } else {
    return false;
  }
};

export const moveOtherToBottom = arrayOfObjects => {
  const result = arrayOfObjects.map(obj =>
    obj.name.toLowerCase() === 'other' ? { ...obj, type: null } : obj
  );

  result.sort((a, b) => {
    if (a.type === null) return 1;
    if (b.type === null) return -1;
    return 0;
  });

  return result;
};
