import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { usePatientContext } from 'context/PatientContext';
import { calculateAge, formatDate, getFullName } from 'helpers/extra';
import EmergencyContacts from './EmergencyContacts';

const PatientProfile = () => {
  const { patientDetails } = usePatientContext();
  const [organizedDetails, setOrganizedDetails] = useState([]);

  useEffect(() => {
    if (patientDetails) {
      let array = [];
      array.push({ title: 'Full Name', value: getFullName(patientDetails) });
      array.push({ title: 'ID', value: patientDetails.hospital_number });
      array.push({
        title: 'Age',
        value: calculateAge(patientDetails.date_of_birth)
      });
      array.push({ title: 'Gender', value: patientDetails.gender });
      array.push({ title: 'Address', value: patientDetails.address });
      array.push({ title: 'Phone Number', value: patientDetails.phone });
      array.push({
        title: 'Created At',
        value: formatDate(patientDetails.created_at)
      });
      setOrganizedDetails(array);
    }
  }, [patientDetails]);

  return (
    <>
      <Card className="mb-3 p-2">
        <FalconCardHeader
          title={<h4 className="fw-semi-bold">Patient Profile</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />
        <Card.Body className="pt-0">
          <Row>
            <Col md={12}>
              <Table
                size="sm"
                borderless
                className="font-sans-serif my-0 w-100"
              >
                <tbody>
                  {organizedDetails?.map(detail => (
                    <tr key={detail.id}>
                      <td className="text-start ps-0">{detail.title}</td>
                      <td className="text-start">{detail.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <EmergencyContacts />
    </>
  );
};

export default PatientProfile;
